<template>
  <div >
    <div >
      <div class="echarts-box content-box">
        <div class="legend-box">
          <p class="legend-btn">
            <span :class="ZorLJ == 'LJ'&&'choiceZorLJ'" @click="ZorLJ = 'LJ'">L-J</span>
            <span :class="ZorLJ == 'Z'&&'choiceZorLJ'" @click="ZorLJ = 'Z'">Z分数</span>
          </p>
          <p class="legend_drop">告警点颜色：<span class="GJ"></span> </p>
          <p class="legend_drop">失控点颜色：<span class="SK"></span> </p>
        </div>
        <div>
          <div v-if="echartsShow&&ZorLJ=='Z'" >
            <line_Z id="innerEchartLine"  :ehartData="zData"></line_Z>
          </div>
          <div v-if="echartsShow&&ZorLJ=='LJ'" >
            <div v-for="(item,index) in ljData" :key="index" >
              <line_LJ :id="item.c1_data.id" :ehartData="item.c1_data"></line_LJ>
              <line_LJ :id="item.c2_data.id" :ehartData="item.c2_data"></line_LJ>
            </div>
            <div v-if="ljData.length == 0" class="lj-seat-box">
              <p>暂无数据</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accept-confirm-box">
        所选项是否全部接受<el-button class="btn" type="primary" :disabled="disabled_accept" size="small" @click="confirmAccept">确定</el-button>
      </div>
      <div class="table-box content-box self-table">
        <el-table ref="data_table_ref" 
          :data="table_data"  style="width: 100%" header-align="center" :header-cell-style="rowClass"  :cell-class-name="cellClassName" border :row-class-name="tableRowClassName"
          table size="mini">
          <el-table-column label="日期" prop="date_time_order" align="center" :width="flexColumnWidth(150)"></el-table-column>
          <el-table-column v-if="isAll" label="质控批号" prop="qc_batch" align="center" :width="flexColumnWidth(140)"></el-table-column>
          <el-table-column label="试剂批号" align="center" prop="batch" :width="flexColumnWidth(140)"></el-table-column>
          <el-table-column label="C1(mg/L)" align="center" :width="flexColumnWidth(130)" class="borde-right-th">
            <template #header>
              <div class="check-cell">
                <span><el-checkbox v-model="c1_all_accepted" :disabled="table_data.length==0" @change="changeAllAccept(1,$event)"></el-checkbox></span>
                <span> C1(mg/L)</span>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="check-cell">
                <span><el-checkbox v-if="scope.row.c1" v-model="scope.row.c1_accept"  @change="changeAcceptList(scope.row.c1_id, $event);judgeAllAcceptStatus()"></el-checkbox></span>
                <span class="weightFont"> {{ scope.row.c1 ?scope.row.c1.toFixed(2) : '-' }}</span>
              </div>
              
            </template>
          </el-table-column>
          <el-table-column label="是否在控" align="center" :width="flexColumnWidth(220)">
            <template slot-scope="scope">
              <div class="report-box">
                <span :class="scope.row.is_c1_lc==true ? 'SK-text' :scope.row.is_c1_lc==false?'GJ-text' : ''">
                  {{ !scope.row.c1?'':scope.row.is_c1_lc ? "失控" : "在控" }}
                </span>
                <el-button class="report-btn" v-if="scope.row.report_tag_c1" size="mini" @click="judgeDoubleLc(scope.row.above_data,1)">{{scope.row.above_data.c1_pdf?'查看':'填写'}}报告</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="是否接受" align="center" :width="flexColumnWidth(150,110)" >
            <template slot-scope="scope">
              <el-switch class="switch" v-model="scope.row.c1_accept" active-text="是" inactive-text="否" v-if="scope.row.c1" 
                @change="changeAcceptFun(scope.row.c1_id, $event)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="违背规则" prop="c1_rule" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="C2(mg/L)" align="center" :width="flexColumnWidth(130)">
            <template #header>
              <div class="check-cell">
                <span><el-checkbox v-model="c2_all_accepted" :disabled="table_data.length==0" @change="changeAllAccept(2,$event)"></el-checkbox></span>
                <span> C2(mg/L)</span>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="check-cell">
                <span><el-checkbox v-if="scope.row.c2" v-model="scope.row.c2_accept" @change="changeAcceptList(scope.row.c2_id, $event);judgeAllAcceptStatus()"></el-checkbox></span>
                <span class="weightFont">{{ scope.row.c2 ? scope.row.c2.toFixed(2) : '-' }}</span>
              </div>
              <!-- mg/L -->
            </template>
          </el-table-column>
          <el-table-column label="是否在控" align="center" :width="flexColumnWidth(220)">
            <template slot-scope="scope">
              <div class="report-box">
                <span :class="scope.row.is_c2_lc ? 'SK-text' :scope.row.is_c2_lc==false?'GJ-text' : ''">
                  {{ !scope.row.c2?'':scope.row.is_c2_lc ? "失控" : "在控"}}
                </span>
                <el-button class="report-btn" v-if="scope.row.report_tag_c2" size="mini" @click="judgeDoubleLc(scope.row.above_data,2)">{{scope.row.above_data.c2_pdf?'查看':'填写'}}报告</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column  label="是否接受" align="center" :width="flexColumnWidth(150,110)">
            <template slot-scope="scope">
              <el-switch class="switch" v-model="scope.row.c2_accept" active-text="是" inactive-text="否" v-if="scope.row.c2"
                @change="changeAcceptFun(scope.row.c2_id, $event)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="违背规则" prop="c2_rule" align="center" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
    </div>
    <div>
      <!-- 弹窗 -->
      <el-dialog
        title=""
        :visible.sync="dialog_double"
        width="35%"
        class="tip-dialog-box"
        :show-close="false">
        <div class="double-box">
          <p>当前时间段C{{temp_index[0]==1?'2':'1'}}也存在失控，是否同时填写C1、C2失控报告？</p>
          <div class="btn-box">
            <el-button type="primary" class="btn" size="small" @click="dialog_double = false;temp_index=[1,2];openControlReport()">是！同时填写</el-button>
          </div>
          <div class="btn-box">
            <el-button class="btn" size="small" @click="dialog_double = false;openControlReport()">不！只填写C{{temp_index[0]}}失控报告</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title=""
        :visible.sync="dialog_data_push"
        width="35%"
        class="tip-dialog-box"
        :show-close="false">
        <div class="double-box">
          <p>数据已刷新,是否同步更新页面</p>
          <div class="btn-box">
            <el-button type="primary" class="btn" size="small" @click="dialog_data_push = false;$emit('change',{})">是</el-button>
          </div>
          <div class="btn-box">
            <el-button class="btn" size="small" @click="dialog_data_push = false;">否</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title=""
        :visible.sync="dialog_all_accept"
        width="35%"
        class="tip-dialog-box"
        :show-close="false">
        <div class="double-box">
          <p>
            {{c1_all_not?'C1':''}}
            {{c2_all_not?'C2':''}}
            已全部去除，是否确认</p>
          <div class="btn-box">
            <el-button type="primary" class="btn" size="small" @click="confirmAllNot">是</el-button>
          </div>
          <div class="btn-box">
            <el-button class="btn" size="small" @click="dialog_all_accept = false;c1_all_not = false;c2_all_not = false">否</el-button>
          </div>
        </div>
      </el-dialog>
      <reportDialog :hospital_id="hospital_id" ref="reportDialog" @success="set_report_data"></reportDialog>
    </div>
  </div>
</template>

<script>
import api from '@/api/quality'

import line_Z from './echarts/line_Z.vue'; 
import line_LJ from './echarts/line_LJ.vue'; 
import {flexColumnWidth} from '@/assets/js/adaption.js'
import reportDialog from './reportDialog.vue';
import indoorMixins from './indoorMixins';


export default {
  mixins: [indoorMixins],
  props: ['hospital_id'],
  components: {
    reportDialog,
    line_Z,
    line_LJ,
  },
  data() {
    return {
      ZorLJ: 'Z',
      table_data: [], //列表数据
      table_original_data: [], //原始列表数据保存
      zData: {}, //Z分数图数据
      ljData: {}, //Z分数图数据
      isAll: false,
      echartsShow: false,
      rowDay: 0,//每日颜色区分日期记录
      rowType: true, //每日颜色区分当前状态
      temp_above_data: {},
      temp_index: '',
      dialog_double: false,
      is_accepted: [],
      not_accepted: [],
      dialog_data_push: false,
      c1_all_accepted: true,
      c2_all_accepted: true,
      dialog_all_accept: false,
      c1_all_not: false,
      c2_all_not: false,
    }
  },
  computed: {
    disabled_accept() {
      return this.is_accepted.length==0&&this.not_accepted.length==0
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    handleScroll() {
      let heightTop = document.getElementsByClassName('indoor-content')[0].scrollTop //距离顶部高度]
      let sticky_top = document.getElementsByClassName('self-table')[0].offsetTop - document.getElementsByClassName('head')[0].clientHeight + 14
      if (heightTop >= sticky_top) { //表头到达页面顶部固定表头
        // 146 为距离顶部多少时开始吸顶，即吸顶距离
        let top = heightTop - sticky_top
        document.getElementsByClassName('el-table__header-wrapper')[0].style.zIndex = '777'
        document.getElementsByClassName('el-table__header-wrapper')[0].style.top = `${top}px`
        document.getElementsByClassName('el-table__header-wrapper')[0].style.position = `sticky`
      } else {
        document.getElementsByClassName('el-table__header-wrapper')[0].style.top = ''
        document.getElementsByClassName('el-table__header-wrapper')[0].style.zIndex = ''
      }
    },

    /* 表格样式 */
    // 表格列宽
    flexColumnWidth(width,minWidth) {
      return flexColumnWidth(width,minWidth)
    },
    // 表格头部样式 
    rowClass({ row, column, rowIndex, columnIndex }){
      let c1StyleList = this.isAll?[3,4,5,6]:[2,3,4,5],
        c2StyleLits = this.isAll?[7,8,9,10]: [6,7,8,9],
        borderList = this.isAll?[6]: [5];
      let style_text = ""
      if(rowIndex == 0 && c1StyleList.includes(columnIndex)){
        style_text +='color:#166DC9;'
      }
      if(rowIndex == 0 && c2StyleLits.includes(columnIndex)){
        style_text += 'color:#7CCE7A;'
      }
      if(borderList.includes(columnIndex)){
        style_text += 'border-right:4px double #EBEEF5'
      }
      return style_text
    },

    cellClassName({row, column, rowIndex, columnIndex}) {
      let borderList = this.isAll?[6,]: [5];
      if(borderList.includes(columnIndex)){
        return "my-cell"
      }
    },
    // 按日期不同颜色区分
    tableRowClassName({row, rowIndex}) {
      return row.rowClass
    },
    /* 数据变化后刷新 */
    push_tableAndChart_data(tableAndChartData) {
      this.is_accepted = []
      this.not_accepted = []
      this.echartsShow = false
      // 列表数据
      this.table_original_data = JSON.parse(JSON.stringify(tableAndChartData.qc_list))
      this.table_original_data.reverse()
      this.table_data = JSON.parse(JSON.stringify(tableAndChartData.qc_list));
      this.table_data.reverse()
      this.judgeAllAcceptStatus();
        //按日期不同颜色区分
      let length = this.table_data.length
      let rowType = false
      if(length>0){
        this.table_data[0].rowClass = '';
      }
      for(let i=1;i<length;i++){
        let nowRwowDay = this.table_data[i].date_time_order.split('.')[0].split('-')[2]
        let oldRowDay = this.table_data[i-1].date_time_order.split('.')[0].split('-')[2]
        if(oldRowDay!=nowRwowDay){
          rowType = !rowType
        }
        this.table_data[i].rowClass = rowType?'dark-row':''
      }

      // 折线图数据
      let dataToEcharts = this.handle_dataToEcharts(tableAndChartData.qc_data,tableAndChartData.qc_list,tableAndChartData.qc_serum_dict)
      this.zData = dataToEcharts.zData
      this.ljData = dataToEcharts.ljData
      this.$nextTick(()=> {
        this.echartsShow = true;
      })
    },
    /* 是否接受 */
    // 是否接受按钮
    changeAcceptFun(qc_point_id,e) {
      let params = {
        hospital_id: this.hospital_id,
      }
      if(e){
        params.is_accepted = [qc_point_id]
        params.not_accepted = []
      }else{
        params.is_accepted = []
        params.not_accepted = [qc_point_id]
      }
      // 初始化数据
      
      api.qc_point_accept(params).then(res => {
        if(res.code == 200) {
          this.$emit('change',{})
        }
      })
    },
    // 是否接受-多选
    changeAcceptList(qc_point_id,e) {
      if(e){
        // 改成了接受
        // 判断在不在不在里面
        if(this.not_accepted.includes(qc_point_id)){
          this.not_accepted = this.not_accepted.filter(e => e !== qc_point_id)
        }else {
          this.is_accepted.push(qc_point_id)
        }
      }else{
        // 改成了不接受
        if(this.is_accepted.includes(qc_point_id)){
          this.is_accepted = this.is_accepted.filter(e => e !== qc_point_id)
        }else {
          this.not_accepted.push(qc_point_id)
        }
      }
    },
    // 判断表头的全选按钮状态
    judgeAllAcceptStatus() {
      let c1_is_accepted = []
      let c2_is_accepted = []
      let c1_not_accepted = []
      let c2_not_accepted = []
      
      this.table_data.forEach(e => {
        if(e.c1){
          e.c1_accept? c1_is_accepted.push(e.c1_id) : c1_not_accepted.push(e.c1_id)
        }
        if(e.c2){
          e.c2_accept? c2_is_accepted.push(e.c2_id) : c2_not_accepted.push(e.c2_id)
        }
      })
      if(c1_is_accepted.length!=0){
        this.c1_all_accepted = c1_not_accepted.length==0? true : false
      }else {
        this.c1_all_accepted = false
      }
      if(c2_is_accepted.length!=0){
        this.c2_all_accepted = c2_not_accepted.length==0? true : false
      }else {
        this.c2_all_accepted = false
      }
    },
    // 全选切换
    changeAllAccept(type,event) {
      this.table_data.forEach(element => {
        if(element['c'+type]){
          if(element['c'+type+'_accept']!=event){
            element['c'+type+'_accept'] = event
            this.changeAcceptList(element['c'+type+'_id'],event)
          }
        }
      })
    },
    // 确认后发送数据
    confirmAccept() {
      // this.confirmAccept = 
      if(!this.c1_all_accepted){
        let c1_temp_list = this.table_data.filter(e => e.c1&&e.c1_accept)
        if(c1_temp_list.length == 0) {
          this.c1_all_not = true
        }
      }
      if(!this.c2_all_accepted){
        let c2_temp_list = this.table_data.filter(e => e.c2&&e.c2_accept)
        if(c2_temp_list.length == 0) {
          this.c2_all_not = true
        }
      }
      if(this.c1_all_not||this.c2_all_not){
        this.dialog_all_accept = true
        return
      }

      let params = {
        hospital_id: this.hospital_id,
        is_accepted: this.is_accepted,
        not_accepted: this.not_accepted,
      }
      let loading = this.$loading({
        lock: true,
        text: '数据更新中，请等待',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      api.qc_point_accept(params).then(res => {
        if(res.code == 200) {
          this.is_accepted = []
          this.not_accepted = []
          this.dialog_data_push = true
        }
      }).finally(msg => {
        loading.close()
      })
    },
    // 全部去除的确认
    confirmAllNot() {
      let params = {
        hospital_id: this.hospital_id,
        is_accepted: this.is_accepted,
        not_accepted: this.not_accepted,
      }
      this.dialog_all_accept = false;
      this.c1_all_not = false;
      this.c2_all_not = false;
      let loading = this.$loading({
        lock: true,
        text: '数据更新中，请等待',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      api.qc_point_accept(params).then(res => {
        if(res.code == 200) {
          this.is_accepted = []
          this.not_accepted = []
          this.dialog_data_push = true
        }
      }).finally(msg => {
        loading.close()
      })
    },
    /* 打开失控报告弹窗 */
    // 是否两个浓度都失控
    judgeDoubleLc(item,index) {
      this.temp_above_data = item
      this.temp_index = [index]

      let c1_pdf = item.c1_pdf
      let c2_pdf = item.c2_pdf
      
      if(item['c'+index+'_pdf']) {
        this.openControlReport();
        return;
      }
      
      
      if(item.is_c1_lc&&item.is_c2_lc){
        if(index == 1){
          if(c2_pdf){
            this.openControlReport();
          }else {
            this.dialog_double = true;
          }
        }else {
          if(c1_pdf){
            this.openControlReport();
          }else {
            this.dialog_double = true;
          }
        }
      }else {
        this.openControlReport();
      }
    },
    // 打开失控报告弹窗
    openControlReport() {
      let qc_point_ids = ''
      this.temp_index.forEach((e,index) => {
        if(index == this.temp_index.length-1){
          qc_point_ids += this.temp_above_data['c' + e + '_id'] 
        }else {
          qc_point_ids += this.temp_above_data['c' + e + '_id'] + ','
        }
      });
      let param = {
        hospital_id: this.hospital_id,
        // c_rule: item['c'+index+'_rule'],
        c1_rule: this.temp_above_data.c1_rule,
        c2_rule: this.temp_above_data.c2_rule,
        qc_instance_id: this.temp_above_data.qc_instance_id,
        qc_serum_id: this.temp_above_data.qc_serum_id,
        qc_point_ids,
      }
  
      this.$refs.reportDialog.open_dialog(param);
    },
    /* 成功后修改列表的报告数据 */
    set_report_data(file_url) {
      if(this.temp_index.length == 1){
        this.temp_above_data['c'+this.temp_index[0]+'_pdf'] = file_url[0];
      }else{
        this.temp_above_data.c1_pdf = file_url[0];
        this.temp_above_data.c2_pdf = file_url[1];
      }
    },
  },
}
</script>

<style lang="scss" scoped>

  .GJ {
    background-color: $alarm-color;
  }
  .SK {
    background-color: $incontrollable-color;
  }
  .SK-text  {
    color: $incontrollable-color;
  }
 .GJ-text {
    color: $alarm-color;
  }
  .content-box {
    margin: $gap;
    background-color: #fff;
    padding: $gap;
    border-radius: $gap;
  }

 
  
  .legend-box{
    display: flex;
    align-items: center;
    gap: 3*$gap;
    .legend-btn {
      &>span {
        display: inline-block;
        cursor: pointer;
        height: px(35);
        line-height: px(35);
        padding: 0 px(30);
        font-size: px(24);
        border-radius: px(4);
        margin-left: px(10);
        color: #ffffff;
        font-weight: bold;
        background: #BEBEBE;
      }
      .choiceZorLJ {
        background: #2D74B6;
        box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, .2);
      }
    }
    .legend_drop {
      display: flex;
      align-items: center;
      &>span {
        width: px(30);
        height: px(18);
        border-radius: 50%/50%;
        display: inline-block;
      }
      
    }
  }
  .lj-seat-box {
    height: px(420);
    width: 99%;
    position: relative;
    &>p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      size: $text-size;
    }
  }

  //表格内样式

  //间隔颜色
  .table-box {
   
    ::v-deep .el-table .dark-row {
      background:  #fafafa !important;
    }
    
    ::v-deep .el-table__body {
      .has-gutter {
        th{
          .cell {
            font-size: $table-th-size;
          }
        }
      }
      .el-table__row {
        td {
          .cell {
            font-size: $table-td-size;
            line-height: px(25);
          }
        }
      }
    } 
   
    ::v-deep.switch {
      font-size: $table-td-size ;
      .el-switch__label {
        &>span {
          font-size: $table-td-size;
          line-height: px(15);
        }
      }
      .el-switch__label--left {
        margin-right: px(10);
      }
      .el-switch__core {
        width: px(40) !important;
        height: px(20) !important;
       // transform: scale(1,0.9);
      }
      .el-switch__core::after {
        width: px(18);
        height: px(18);
        margin-top: -px(1);
      }
      
      .el-switch__label--right {
        margin-left: px(10);
      }
    }
    ::v-deep.el-switch.is-checked .el-switch__core::after {
      margin-left: -px(20) !important;
    }

    .report-box {
      position: relative;
      width: 100%;
      font-size: $table-td-size;
      .report-btn {
        position: absolute;
        right: 0;
        top:  50%;
        transform: translate(0,-50%);
        padding: px(3);
        font-size: $btn-text-size;
      }
    }
  }
  
  ::v-deep .my-cell {
    border-right: 4px double #EBEEF5;
  }
  ::v-deep .my-cell::before {
    width: 2px;
    height: 3px;
    background-color: red;
    
  }
  .tip-dialog-box {
    ::v-deep.show-close {
      height: 0;
      padding: 0;
    }
    ::v-deep.el-dialog__header {
      padding: 0;
    }
    ::v-deep.el-dialog__body {
      padding: px(20);
    }
    ::v-deep.el-dialog {
      margin-top: 30vh !important;
    }
    .double-box {
      &>p {
        font-size: px(20);
        text-align: center;
      }
      
    }
    .btn-box {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .btn {
        width: 50%;
        margin-top: $gap;
      }
    }
  }
  .accept-confirm-box {
    padding-left: $gap;
    display: flex;
    align-items: center;
    margin: $gap $gap -px(5);
    font-size: px(20);
    .btn {
      margin-left: $gap;
      width: px(120);
      font-size: px(18);
    }
  }
  .check-cell {
    display: flex;
    align-items: center;
    &>span:first-child {
      text-align: left;
      ::v-deep .el-checkbox__inner{
        width: px(16);
        height: px(16);
      }
      ::v-deep .el-checkbox__inner::after{
        height: px(10);
        left: px(6);
      }
    }
    &>span:last-child {
      width: calc(100% - px(18));
      text-align: center;
    }
  }
</style>