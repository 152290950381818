<template>
  <div class="innerEchartLine">
    <img src="" alt="" :id="id" class="echartsImg" v-show="!isshow"/>
    <div :id="id+'2'" class="echarts" :style="'width:100%'" v-show="isshow"></div>
   
  </div>
</template>
<script>
import echartsMixins from "@/assets/js/echartsMixins.js"

export default {
  props: ['id','ehartData'],
  mixins: [echartsMixins],

  data() {
    return {
      myChart: '',
      tipTop: 0,
      is_tipDel: false,
      tipLeft: 0,
      delData: {
        realValue: '',
        cl: '',
        batch: '',
      },
      color: [
        '#999999',
        '#F75151',
        '#FFC200',
        '#19CB63',
        '#0073CD',
        '#19CB63',
        '#FFC200',
        '#F75151',
        '#999999',
      ],
      isshow: true,
      dataY2: ['0SD'],
      widthEchart: '100%',
      minNum: 0,
      maxNum: 0,
    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.initEcharts()
      },
      deep: true,
    },
  },
  created() {
    // for(let i=1;i<=4;i++) {
    //   this.dataY2.push(`+${i}SD`)
    //   this.dataY2.unshift(`-${i}SD`)
    // }
    // let length = (this.ehartData.data.length/40).toFixed(2)
    // length = length<1? 100 : length*100
    // this.widthEchart = length+'%'
    // let a = document.getElementById(this.id)
    // // a.style.width=150+'%'
  },
  mounted() {
    // this.getMaxMinFun()
    // this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.initEcharts()
  },

  methods: {
     // 刷新组件
     handlerResize(){
      this.myChart.resize()
    },
    initEcharts() {
      var chartDom = document.getElementById(this.id+'2')
      var myChart = this.$echarts.init(chartDom)


      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
            },
          },
        },
        legend: {
          data: ['均值', 'CV'],
        },
        grid: {
          left: '1%',
          right: '3%',
          bottom: '2%',
          top: '20%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.ehartData.month_list,
            axisPointer: {
              type: 'shadow',
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '均值',
            min: 0,
            axisLabel: {
              formatter: '{value} ',
            },
          },
          {
            type: 'value',
            name: 'CV%',
            min: 0,
           
            axisLabel: {
              hideOverlap: true,
              formatter: '{value} ',
            },
            splitLine: {
              lineStyle: {
                color: '#77C6F5',
                opacity: 0.5
              }
            }
          },
        ],
        series: [
          {
            name: '均值',
            type: 'bar',
            barWidth: 40,
            itemStyle: { 
              normal: {
                color: '#00afe9',
              },
            },
            data: this.ehartData.target_list,
          },
          {
            name: 'CV',
            type: 'line',
            connectNulls: true,
            yAxisIndex: 1,
            itemStyle: {
              color: '#999999',
              
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + ' %'
              },
            },
            data: this.ehartData.cv_list,
          },
        ],
      }

      // this.myChart.setOption(option)

      myChart.setOption(option)

      // setTimeout(() => {
      //    //下面的代码就是把echarts图表转成图片再渲染
      //   var imgSrc = myChart.getDataURL() // 渲染到图表上面展示
      //   let imgDemo = document.getElementById(this.id)
      //   imgDemo.src = imgSrc;
      //   this.isshow = false;
      // }, 2000);
     

    },
  },
}
</script>
<style lang="scss" scoped>
.divBG {
  background: gray;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0 !important;
  right: 0;
  bottom: 0;
  z-index: 99;
}
.innerEchartLine {
  width: 100%;
  overflow-x: auto;
  height: px(300);
  position: relative;
  .title {
    font-weight: bold;
    color: #2d354c;
    border-left: px(5) solid #00afe9;
    padding-left: px(10);
    position: absolute;
    left: px(20);
    top: px(10);
  }
  .echarts {
    // width: 100%;
    height: px(300);
  }
  .echartsImg{
    height: px(300);
    width: 100%;
  }
}
</style>