<template>
  <div class="innerEchartLine">
    <p class="name-box" v-show="legendShow">{{ehartData.data.name}}</p>
    <div :id="id" :class="legendShow?'echartslegend':'echarts'" ></div>
    <div class="tip-noData" v-show="ehartData.dataX.length === 0">
      暂无数据
    </div>
  </div>
</template>
<script>
import echartsMixins from "@/assets/js/echartsMixins.js"

export default { 
  mixins: [echartsMixins],

  props: {
    legendShow: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,

    },
    ehartData: {
      type: Object,
    }
  },
  data() {
    return {
      myChart: '',
      tipTop: 0,
      is_tipDel: false,
      tipLeft: 0,
      delData: {
        realValue: '',
        cl: '',
        batch: '',
      },
      color: [
        '#999999',
        '#F75151',
        '#FFC200',
        '#19CB63',
        '#0073CD',
        '#19CB63',
        '#FFC200',
        '#F75151',
        '#999999',
      ],
      dataY2: [
        '0SD',
      ],
      widthEchart: '100%',
      minNum: 0,
      maxNum: 0,
      textInterval: false,
      
    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.initEcharts()
      },
      deep: true,
    },
  },
  created() {
    // for(let i=1;i<=4;i++) {
    //   this.dataY2.push(`+${i}SD`)
    //   this.dataY2.unshift(`-${i}SD`)
    // }
      // let length = (this.ehartData.data.length/40).toFixed(2)
      // length = length<1? 100 : length*100
      // this.widthEchart = length+'%'
      // let a = document.getElementById(this.id)
      // a.style.width=150+'%'
      
  },
  mounted() {
    this.getMaxMinFun()
    this.handle_data();
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.initEcharts()
  },

  methods: { 
     // 刷新组件
     handlerResize(){
      this.myChart.resize()
    },
    handle_data() {
      // let length = this.ehartData?.dataX.length
      // if(length>0){
      //   this.ehartData.dataX = ['',...this.ehartData.dataX,'']
      //   this.ehartData.data.data =  [{value: null},...this.ehartData.data.data,{value: null}]
      // }
     
      // let need_length = 3
      // let length = this.ehartData.dataX.length
      // if(length>1&&length<need_length){
      //   let length2 = need_length - length
      //   let arr1 = new Array(length2).fill('')
      //   let arr2 = new Array(length2).fill({value: null})
      //   this.ehartData.dataX = [...arr1,...this.ehartData.dataX,...arr1]
      //   this.ehartData.data.data =  [...arr2,...this.ehartData.data.data,...arr2]
      // }
      // if(length>=need_length){
      //   this.ehartData.dataX = ['',...this.ehartData.dataX,'']
      //   this.ehartData.data.data =  [{value: null},...this.ehartData.data.data,{value: null}]
      // }
    },
    //   获取最大最小值
    getMaxMinFun() {
      let data = []
      this.ehartData.data.data.forEach(e => {
        if(e.value){
          data.push(e.value)
        }
      });
      let maxNum = Math.max.apply(null, data),
          minNum = Math.min.apply(null, data)
      let maxTemp = maxNum - this.ehartData.target,
          minTemp = minNum - this.ehartData.target;
      let a = parseInt(maxTemp/this.ehartData.sd),
          b = parseInt(minTemp/this.ehartData.sd);
      a>=0?a++:a--
      b>=0?b++:b--
      

      this.maxNum = a>4?this.ehartData.target+a*this.ehartData.sd:
                        this.ehartData.target+4*this.ehartData.sd
      this.minNum = b<-4?this.ehartData.target+b*this.ehartData.sd:
                        this.ehartData.target-4*this.ehartData.sd
      this.maxNum = this.maxNum.toFixed(2);
      this.minNum = this.minNum.toFixed(2);

      let colorA = [];
      let colorB = [];
      colorA = a-4<0?[]:new Array(a-4).fill('#999999')
      colorB = b+4>0?[]:new Array(-4-b).fill('#999999')
      this.color = [...colorB,...this.color,...colorA]
    },

    initEcharts() {
      var _this = this
      var option = {
        // legend: {
        //   show: true,
        //   type: 'plain',
        //   top: '1%',
        //   right: '1%',
        //   itemGap: 20, // 间隔距离
        //   data: this.ehartData.name,
        // },
        // dataZoom : [
				// 		{
				// 			type: 'inside',
				// 			show: true,
				// 			start: 0,
				// 			end: 100,
				// 			xAxisIndex: [0],
				// 		},
				// 	],
        tooltip: {
          show: true,
          trigger: 'item',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'line', // 默认为直线，可选为：'line' | 'shadow'
          },
          padding: [0, 0, 0, 0],
          backgroundColor: 'rgba(255,255,255)',
          // borderRadius: 0,
          extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
          formatter: function (param) {
            let htmlTemplate = `<div style='background:#fff;width:156px;padding:5px 15px;'>
        
              <p style='color:#666666;font-size: 24upx;'>时间：<strong style='color:#000000;font-size: 24upx;'>${
                  param.name ? param.name : '-'
                }</strong></p>
              <p style='color:#666666;font-size: 24upx;'>试剂批号：<strong style='color:#000000;font-size: 24upx;'>${
                param.data.batch ? param.data.batch : '-'
              }</strong></p>
              <span style='color:#666666;font-size: 24upx;'>结果：<strong style='color:#000000;font-size: 24upx;'>${
                param.data.realValue ? param.data.realValue : '-'
              }</strong></span><br/>
              <span style='color:#666666;font-size: 24upx;'>评估：<strong style='color:#000000;font-size: 24upx;'>${
                param.data.cl == true
                  ? '失控'
                  :  param.data.cl == false
                  ? '告警'
                  : '在控'
              }</strong></span><br/>
              <span style='color:#666666;font-size: 24upx;'>违背规则：<strong style='color:#000000;font-size: 24upx;white-space:normal'>${
                param.data.rule ? param.data.rule : '-'
              }</strong></span></div>
              `
            return htmlTemplate
          },
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '0%',
          top: '10%',
          containLabel: true,
        },
        xAxis: [
          {
            data: this.ehartData.dataX,
            boundaryGap: false,
            
            // 控制网格线是否显示
            splitLine: {
              show: false,
            },
            //  改变x轴颜色
            axisLine: {
              show: false,
              lineStyle: {
                width: 2,
                color: '#D7D7D7',
              },
            },
            axisTick: { show: false },
            //  改变x轴字体颜色和大小
            axisLabel: {
              margin: 15,
              textStyle: {
                color: '#9AA1A9',
                fontSize: '14',
              },
            },
          },
        ],
        yAxis: [
          // 左边轴
          {

            type: 'value',
            axisLine: { onZero: false },
            axisLabel: {
              formatter: '{value}'
            },
            boundaryGap: false,
            min: this.minNum,
            max: this.maxNum,
            interval: _this.textInterval?this.ehartData.sd:this.ehartData.sd,
            // axisTick: { show: false },
            // axisLine: { show: false },
            axisLabel: {
              hideOverlap: true,
              textStyle: {
                color: function (param, index) {
                  return  _this.color[index]
                },
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: this.color,
              },
            },
          },
          
        ],
         
        series: [
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
              width: 0.5,
              type: 'solid',
              color: '#999999',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(this.ehartData.target-4*this.ehartData.sd),
          },
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
              width: 0.5,
              type: [8,8],
              color: '#F75151',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(this.ehartData.target-3*this.ehartData.sd),
          },
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
              width: 0.5,
              type: [5,5,2,5],
              color: '#FFC200',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(this.ehartData.target-2*this.ehartData.sd),
          },
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
              width: 0.5,
              type: 'dashed',
              color: '#19CB63',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(this.ehartData.target-this.ehartData.sd),
          },
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
              width: 0.5,
              type: 'solid',
              color: '#0073CD',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(this.ehartData.target),
          },
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
              width: 0.5,
              type: 'dashed',
              color: '#19CB63',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(this.ehartData.target+this.ehartData.sd),
          },
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
              width: 0.5,
              type: [5,5,2,5],
              color: '#FFC200',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(this.ehartData.target+2*this.ehartData.sd),
          },
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
              width: 0.5,
              type: [8,8],
              color: '#F75151',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(this.ehartData.target+3*this.ehartData.sd),
          },
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
              width: 0.5,
              type: 'solid',
              color: '#999999',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(this.ehartData.target+4*this.ehartData.sd),
          },
          this.ehartData.data
        ],
      }
      this.myChart.on('click',(params)=>{
        // 兄弟组件通信，调整table的数据位置
        eventBus.$emit('scrollValue', params.data.qc_instance_id);

      }),
      this.myChart.setOption(option)
      // this.myChart.resize({ height: '300px' })
    },
  },
}
</script>
<style lang="scss" scoped>
.name-box {
  margin-left: 1%;
  font-size: $text-size;
  font-weight: bold;
  margin-top: $gap;
}

.innerEchartLine {
  width: 100%;
  overflow-X: auto;
  //height: 300px;
  height: px(390);
  position: relative;

  .echartslegend {
    width: 99% ;
    height: px(340);
  }
  .echarts {
    width: 99% ;
    height: px(390);
  }
}


</style>