<template>
  <div class="indoor-box">
    <div class="indoor-content">
      <div class="titleText">
        {{ hospital_name }}
      </div>
      <ul class="search-box">
        <li  >
          <el-select 
            class="select"
            v-model="valueJh"
            placeholder="请选择机号"
            :size="size"
            filterable
            @change="changeJh"
          >
            <el-option
              v-for="item of optionsJh"
              :key="item.instrument_id"
              :label="item.instrument_id"
              :value="item.instrument_id"
            ></el-option>
          </el-select>
        </li>
        <li  >
          <el-select 
            v-model="valueXm"
            placeholder="请选择检测项目"
            :size="size"
            filterable
            @change="changeXm"
          >
            <el-option
              v-for="item of optionsXm"
              :key="item.testing_id"
              :label="item.testing_unique"
              :value="item.testing_id"
            ></el-option>
          </el-select>
        </li>
        <li  >
          <el-select 
            v-model="valuePh"
            placeholder="请选择质控品批号"
            :size="size"
            filterable
            @change="changePh"
          >
            <el-option
              v-for="item of optionsPh"
              :key="item.qc_batch"
              :label="item.qc_batch"
              :value="item.qc_batch"
            ></el-option>
          </el-select>
        </li>
        <li  >
          <el-select 
            v-model="valueTimeType"
            placeholder="请选择日期类型"
            :size="size"
            filterable
            @change="changeTimeType"
          >
            <el-option
              v-for="item of optionTimeType"
              :key="item.timeType_id"
              :label="item.timeType_text"
              :value="item.timeType_id"
            ></el-option>
          </el-select>
        </li>
        <li v-show="valueTimeType === 1" class="li-datePick-box">
          <el-date-picker
            v-model="valueMonth"
            type="month"
            format='yyyy/MM'
            value-format="yyyy-MM"
            :size="size"
            placeholder="选择月"
            class="calendarDate"
            :picker-options="{}"
            @change="chanageMonth"
            >
          </el-date-picker>
        </li>
        <li v-show="valueTimeType === 2" class="li-timePick-box">
          <el-date-picker
            class="calendarDate"
            v-model="valueTime"
            :size="size"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
          <!-- <timePickerCloud ref="timePickerCloud" :size="size" :pickerOptions="{}" :end_stats_dayOld="end_stats_day" :start_stats_dayOld="start_stats_day" @setTime="setTime" ></timePickerCloud> -->
        </li>
        <li>
          <el-button type="primary" class="btn" :size="size" @click="confirm_screen">确认筛选</el-button>
        </li>
        <li>
          <el-button  class="btn"  :size="size" @click="set_default">设为默认机号&检测项目</el-button>
        </li>
      </ul>
      <div  v-show="endtime_list.length>0">
        <el-carousel direction="vertical"  class="endtime-box">
          <el-carousel-item v-for="(item,index) in endtime_list" :key="index">
           <div class="endtime-item-box" v-if="item.remaining_days>0">
            <p>重要提示：机号<span>{{item.qc_serum_id.split('-')[0]}}({{textTesting_unique(item.qc_serum_id.split('-')[1])}}/{{ item.qc_serum_id.split('-')[2] }})</span>离定靶结束时间还有<span>{{item.remaining_days}}</span>天，请尽快完成定靶操作。</p>
            <el-button type="primary" class="btn" :size="size" @click="open_qcpTargetDataDialog(item.qc_serum_id)">查看定靶数据</el-button>
           </div>
           <div v-else class="endtime-item-box">
            <p>重要提示：机号<span>{{item.qc_serum_id.split('-')[0]}}({{textTesting_unique(item.qc_serum_id.split('-')[1])}}/{{ item.qc_serum_id.split('-')[2] }})</span>未完成定靶，请联系工程师处理。</p>
           </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="function-box">
        <div class="function-btn-box">
          <el-button type="primary" class="btn" :size="size" @click="open_monthlyReport">查看月度报告</el-button>
          <el-button type="primary" class="btn QCProduct-btn" :size="size" @click="open_QCProduct">
            质控品管理
            <i class="el-icon-caret-bottom el-icon--right"></i>
            <template>
              <div class="QCProduct-btnbox" v-show="showQCProductBtnList">
                <el-button :size="size"  @click="open_QCProductInfo()">查看质控品</el-button>
                <el-button :size="size"  @click="open_addQCProducto">新增质控品</el-button>
              </div>
            </template>
          </el-button>
        </div>
        <div class="function-body-box">
          <div class="left">
            
            <div>
              <p>已选条件 </p>
              <p>
                <span>{{textInstrument}}</span>
                <span>{{textTesting_unique(textTesting)}}</span>
                <span>{{textqcBatch}}</span>
                <span>{{textTime}}</span>
              </p>
              
            </div>
            <p>
              <el-button v-if="showIntervalBtn" class="btn" :size="size" @click="open_intervalDialog">查看累计均值</el-button>
            </p>
          </div>
          <div class="right">
            <div v-show="qc_data.length != 1">
              <el-tabs tab-position="left" v-model="value_Bz"  style="height: 150px;">
                <el-tab-pane v-for="(item,index) in qc_data" :name="index.toString()" :key="index" :label="item.qc_batch" ></el-tab-pane>
              </el-tabs>
            </div>
            <div class="table-box"> 
              <table border="1" class="target-table" >
                <tbody class="target-tbody">
                  <tr>
                    <td rowspan='3' class="target-btn-box no-left-border no-bottom-border" >
                      <div v-if="qc_data[value_Bz].is_using_fixed">
                        <p>固定均值</p>
                      </div>
                      <div v-else>
                        <p class="">靶值</p>
                        <el-button type="primary" class="btn" :size="size" @click="open_qcpTargetDataDialog(qc_data[value_Bz].qc_serum_id)">查看定靶数据</el-button>
                      </div>
                    </td>
                    <td class="th data-th">水平</td>
                    <td class="th data-th">N</td>
                    <td class="th data-th xbar" >X</td>
                    <td class="th data-th" >SD</td>
                    <td class="th data-th no-right-border" >CV</td>
                  </tr>
                  <tr>
                    <td>C1</td>
                    <td>{{ qc_data[value_Bz].is_using_fixed?'-':qc_data[value_Bz].c1_cnt?qc_data[value_Bz].c1_cnt:0 }}</td>
                    <td>{{ textTarget(qc_data[value_Bz].fixed_target_c1)}}</td>
                    <td>{{ textTarget(qc_data[value_Bz].fixed_sd_c1) }}</td>
                    <td class="no-right-border">{{qc_data[value_Bz].is_using_fixed? '-' : textCV(qc_data[value_Bz].fixed_cv_c1)+'%' }}</td>
                  </tr>
                  <tr>
                    <td>C2</td>
                    <td>{{ qc_data[value_Bz].is_using_fixed?'-':qc_data[value_Bz].c2_cnt?qc_data[value_Bz].c2_cnt:0 }}</td>
                    <td>{{ textTarget(qc_data[value_Bz].fixed_target_c2) }}</td>
                    <td>{{ textTarget(qc_data[value_Bz].fixed_sd_c2) }}</td>
                    <td class="no-right-border">{{qc_data[value_Bz].is_using_fixed? '-' : textCV(qc_data[value_Bz].fixed_cv_c2)+'%' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <!-- <el-tabs tab-position="left">
              <el-tab-pane v-for="(item,index) in qc_data" :key="index" :label="item.qc_batch" >
                <table border="1" class="target-table" >
                  <tbody class="target-tbody">
                    <tr>
                      <td rowspan='3' class="target-btn-box">
                        <div v-if="item.is_using_fixed">
                          <p>固定均值</p>
                        </div>
                        <div v-else>
                          <p class="">靶值</p>
                          <el-button type="primary" class="btn" :size="size" @click="open_qcpTargetDataDialog">查看定靶数据</el-button>
                        </div>
                      </td>
                      <td class="th">水平</td>
                      <td class="th">N</td>
                      <td class="th">x̅</td>
                      <td class="th">SD</td>
                      <td class="th">CV</td>
                    </tr>
                    <tr>
                      <td>C1</td>
                      <td>N</td>
                      <td>{{textTarget(item.target_c1)}}</td>
                      <td>{{ textTarget(item.sd_c1) }}</td>
                      <td>{{ textCV(item.cv_c1) }}</td>
                    </tr>
                    <tr>
                      <td>C2</td>
                      <td>N</td>
                      <td>{{ textTarget(item.target_c2) }}</td>
                      <td>{{ textTarget(item.sd_c2) }}</td>
                      <td>{{ textCV(item.cv_c2) }}</td>
                    </tr>
                  </tbody>
                </table>
              </el-tab-pane>
            </el-tabs> -->
           
          </div>
        </div>
      </div>
      <tableAndChart ref="tableAndChart" class="tableAndChart-box" @change="change_data"></tableAndChart>
    </div>
    <div><!-- 弹窗位置 -->
      <!-- 月度报告 -->
      <monthlyDialog ref="monthlyDialog"></monthlyDialog>
      <!-- 新增质控品 -->
      <addQCProductDialog :hospital_id="hospital_id" :optionsJh_all="optionsJh_all" ref="addQCProductDialog" @addSuccess="addSuccess_get_qc_serum"></addQCProductDialog>
      <!-- 质控品管理 -->
      <QCProductInfoDialog :hospital_id="hospital_id" ref="QCProductInfoDialog"  :nowDate="nowDate" @close_success="refresh_data" @delSucess="delSucess_get_qc_serum" @setTargetSuccess="refresh_get_qc_serum" @setNumSuccess="refresh_get_qc_serum"></QCProductInfoDialog>
      <!-- 查看定靶数据 -->
      <qcpTargetDataDialog :hospital_id="hospital_id" ref="qcpTargetDataDialog"  :nowDate="nowDate" @setTargetSuccess="refresh_listAndtarget"></qcpTargetDataDialog>
      <!-- 查看累计均值 -->
      <el-dialog
        :visible.sync="dialogVisible_interval"
        :width="flexColumnWidth(800)"
        custom-class="dialog_center"
        :show-close="false"
      >
        <div class="dialog-center-box interval-box">
          <div class="top">
            <p>查看累计均值</p>
            <i class="el-icon-close" @click="dialogVisible_interval=false"></i>
          </div>
          <div class="body">
            <el-tabs v-model="valueIntervalPh" @tab-click="changeTabs"  v-if="list_interval_target.length != 1">
              <el-tab-pane v-for="(item,index) in list_interval_target" :key="index" :label="item.name" :name="index.toString()"> 
              </el-tab-pane>
            </el-tabs>
            <table border="1" class="indoor-table interval_table">
              <thead class="indoor-thead">
                <tr>
                  <th></th>
                  <th>N</th>
                  <th class="xbar">X</th>
                  <th>SD</th>
                  <th>CV</th>
                </tr>
              </thead>
              <tbody class="indoor-tbody">
                <tr>
                  <td class="th">C1</td>
                  <td>{{ list_interval_target[valueIntervalPh].c1_cnt?list_interval_target[valueIntervalPh].c1_cnt:0 }}</td>
                  <td>{{ textTarget(list_interval_target[valueIntervalPh].target_c1) }}</td>
                  <td>{{ textTarget(list_interval_target[valueIntervalPh].sd_c1) }}</td>
                  <td>{{ textCV(list_interval_target[valueIntervalPh].cv_c1) }}%</td>
                </tr>
                <tr>
                  <td class="th">C2</td>
                  <td>{{ list_interval_target[valueIntervalPh].c2_cnt?list_interval_target[valueIntervalPh].c2_cnt:0 }}</td>
                  <td>{{ textTarget(list_interval_target[valueIntervalPh].target_c2) }}</td>
                  <td>{{ textTarget(list_interval_target[valueIntervalPh].sd_c2) }}</td>
                  <td>{{ textCV(list_interval_target[valueIntervalPh].cv_c2) }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="footed">
            <el-button :size="size"  @click="dialogVisible_interval=false">关闭</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from '@/api/quality'
import indoorMixins from './components/indoorMixins'
import { flexColumnWidth } from '@/assets/js/adaption.js'

import TJDetailTitle from '@/components/TJDetailTitle'
import tableAndChart from './components/tableAndChart'
import timePickerCloud from '@/components/timePickerCloud.vue';
import monthlyDialog from './components/monthlyReport/monthlyDialog.vue';
import addQCProductDialog from './components/QCProduct/addQCProductDialog.vue';
import QCProductInfoDialog from './components/QCProduct/QCProductInfoDialog.vue';
import qcpTargetDataDialog from './components/QCProduct/qcpTargetDataDialog.vue';

export default {
  mixins: [indoorMixins],
  components: {
    TJDetailTitle,
    tableAndChart,
    timePickerCloud,
    monthlyDialog,
    addQCProductDialog,
    QCProductInfoDialog,
    qcpTargetDataDialog,
  },
  data() {
    return {
      size: 'medium', //组件样式
      endtime_list: '', // 结束时间提示
      hospital_name: '云质控页面', //医院名称
      hospital_id: '', //医院ID
      nowDate: '', //当前日期
      query: '', //其他页面传过来的数据
      userInfo: JSON.parse(localStorage.getItem('userInfo')), //个人数据
      default_qc_serum_id: '',
      optionsJh_all:[], //所有机号
      
      optionsJh: [],//机号列表
      valueJh: '', //选择的机号
      optionsXm: [{testing_id:4,testing_unique:'hs-CRP'},{testing_id:11,testing_unique:'SAA'}],//项目列表
      valueXm: '',//选择的项目
      ph_list_crude: [],//质控品批号原数据
      optionsPh: [],//质控品批号
      valuePh: '',//选择的质控品批号
      optionTimeType: [{timeType_id:1,timeType_text:'指定月份'},{timeType_id:2,timeType_text:'指定日期'}],
      valueTimeType: 2, //是否是月份选择
      start_stats_day: '', //选择的开始时间
      end_stats_day: '', //选择的结束时间
      valueMonth: '', //选择的月份
      valueTime: ['',''],
      
      list_qcp_info_crude: [], //质控品源数据
      // 已选条件文字显示 开始
      textInstrument: '',
      textTesting: '',
      textqcBatch: '',
      textTime: '',
      showIntervalBtn: false, //查看累积均值按钮是否显示
      // 已选条件文字显示 结束
      qc_data: [
        {
          qc_batch: '',
          is_using_fixed: true,
          target_c1: '',
          sd_c1: '',
          cv_c1: '',
          target_c2: '',
          sd_c2: '',
          cv_c2:'',
          stats: {},
        }
      ],//界面靶值显示
      value_Bz: '0',//当前展示的靶值
      showQCProductBtnList: false, //质控品管理相关

      dialogVisible_interval: false, //累积均值弹窗
      valueIntervalPh: '0',//当前展示的累计均值
      list_interval_target: [{
        name: '',
        c1_cnt: '',
        c2_cnt: '',
        target_c1: '',
        target_c2: '',
        sd_c1: '',
        sd_c2: '',
        cv_c1: '',
        cv_c2: '',
      }], //累计均值
      tableAndChartData: {}, //主界面的图和列表原始数据
    }
  },
  created() {
    /* 
      获取当前时间 当前时间 yyyy-MM-DD
    */
    this.nowDate = new Date().toLocaleDateString('az', { year: 'numeric', month: '2-digit', day: '2-digit' })
  },
  mounted() { 
    let head_height = document.getElementsByClassName('head')[0].clientHeight + 10
    document.getElementsByClassName('indoor-content ')[0].style.height = `calc(100vh - ${head_height}px)`

    /* 
      1、判断查看的是自身医院数据还是其他医院数据
      2、初始化数据 
        2.1获取该医院的所有机号
        2.2获取基本信息数据
        2.3获取所有质控品数据
    */
    // 判断医院，并设置当前医院名称和ID
    this.query = this.$route.query
    if (JSON.stringify(this.$route.query) == '{}') {
      this.hospital_name = this.userInfo.organization_name
        this.hospital_id = 
        this.userInfo.organization == 1 ? 10000 : this.userInfo.organization
    } else {
      this.hospital_name = this.query.hospital_name
      this.hospital_id = 
      this.query.hospital_id == 1 ? 10000 : this.query.hospital_id
      this.default_qc_serum_id = this.query.qc_serum_id
    }
    // 初始化数据
    let loading = this.$loading({
      lock: true,
      text: '数据搜索中，请等待',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    Promise.all([
      this.get_qc_hosipital_instruments(),
      this.getQcHospitalInfo(),
      this.get_qc_serum_info(),
      // this.get_target_endtime_message(),
    ]).then(res => {
      // 质控品接口比初始数据接口先返回时，此次再次设置均值信息防止出错
      this.set_jhOption_and_xmOption('cs');
      this.set_ph_list_crude();
    }).finally(msg=> {
      this.handle_dataToTargetTable();
      loading.close()
    })
  },
  methods: {
    // 返回上一页
    goback() {
      window.history.back()
    },
    // 表格列宽
    flexColumnWidth(width, minWidth) {
      return flexColumnWidth(width, minWidth)
    },
    /* 获取默认机号并搜索数据 */
    async getQcHospitalInfo() {
      if(this.default_qc_serum_id==""){
        try {
          const res = await api.default_qc_setting({hospital_id:this.hospital_id})
          this.default_qc_serum_id = res.data.qc_serum_id
        } catch (error) {
          return 
        }
      }
      let qc_serum_id_textList = this.default_qc_serum_id.split('-')
      this.valueJh = qc_serum_id_textList[0]; //默认机号
      this.valueXm = Number(qc_serum_id_textList[1]); //默认项目
      this.valuePh = qc_serum_id_textList[2]; //默认批号
      // 将当前日期设置为需要的日期（向前推30天）
      if(this.query.panel_time&&this.nowDate != this.query.panel_time){
        this.valueTime = [this.query.panel_time,this.nowDate]
      }else {
        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 30);
        this.valueTime = [currentDate.toLocaleDateString('az', { year: 'numeric', month: '2-digit', day: '2-digit' }),this.nowDate]
      }
      // 设置 已选条件文字描述
      this.textInstrument = qc_serum_id_textList[0]
      this.textTesting = Number(qc_serum_id_textList[1])
      this.textqcBatch = qc_serum_id_textList[2]
      this.textTime = `${this.valueTime[0]}~${this.valueTime[1]}`
      let param = {
        hospital_id: this.hospital_id, //医院标识
        testing_id: this.valueXm, //检测项目标识
        instrument_id: this.valueJh, //机号
        qc_serum_id: this.default_qc_serum_id,
        start_date: this.valueTime[0],
        end_date: this.valueTime[1]
      }
      const res2 = await this.get_screen_data(param)
    },
    /* 获取基本信息数据 */
   /*  get_qc_hospital_info() {
      return new Promise((resolve, reject)=> {
        let param = {
          hospital_id: this.hospital_id,
        }
        api.qc_hospital_info(param).then((res=> {
          // 无数据时直接返回
          if(!res.data){
            resolve()
            return
          }

          // 设置当前默认数据 机号 项目 批号 时间
          
          let qc_serum_id_textList = this.default_qc_serum_id?this.default_qc_serum_id.split('-'):res.data.qc_serum_id.split('-')
          this.valueJh = qc_serum_id_textList[0]; //默认机号
          this.valueXm = Number(qc_serum_id_textList[1]); //默认项目
          this.valuePh = qc_serum_id_textList[2]; //默认批号
          var currentDate = new Date();
          // 将当前日期设置为需要的日期（向前推30天）
          currentDate.setDate(currentDate.getDate() - 30);
          this.valueTime = [currentDate.toLocaleDateString('az', { year: 'numeric', month: '2-digit', day: '2-digit' }),this.nowDate]
          // 设置 已选条件文字描述
          this.textInstrument = qc_serum_id_textList[0]
          this.textTesting = Number(qc_serum_id_textList[1])
          this.textqcBatch = qc_serum_id_textList[2]
          this.textTime = `${this.valueTime[0]}~${this.valueTime[1]}`
          // this.showIntervalBtn = qc_info.qc_list.length!=0
          // 设置靶值数据 
          // this.qc_data = qc_info.qc_data
          // 列表数据设置
          // this.tableAndChartData = qc_info
          // this.push_tableAndChart_data();
          let param = {
            hospital_id: this.hospital_id, //医院标识
            testing_id: this.valueXm, //检测项目标识
            instrument_id: this.valueJh, //机号
            qc_serum_id: res.data.qc_serum_id,
            start_date: this.valueTime[0],
            end_date: this.valueTime[1]
          }

          this.get_screen_data(param).then(res => {
            this.handle_dataToTargetTable()
            resolve()
          })

        })).catch(err=>{
          reject(err)
        })
      })
    }, */
    /* 获取质控品定靶结束时间消息通知  */
    get_target_endtime_message() {
      return new Promise((resolve, reject) => {
        let param = {
        hospital_id: this.hospital_id,
        }
        api.target_endtime_message(param).then(res => {
          this.endtime_list = res.data
          resolve();
        })
      })
      
    },
    /* 刷新主页图表数据 */
    push_tableAndChart_data() {
      this.$refs.tableAndChart.push_tableAndChart_data(this.tableAndChartData )
    },
    /* 搜索医院对应的机号 */
    get_qc_hosipital_instruments() {
      return new Promise((resolve, reject) => {
        if (this.hospital_id === 10000) {
          //厂内则直接设定机号
          this.optionsJh_all = [
            { hos_pos_name: '10M06014', instrument_id: '10M06014' },
            { hos_pos_name: '10L04019', instrument_id: '10L04019' },
            { hos_pos_name: '10K09003', instrument_id: '10K09003' },
            { hos_pos_name: '10N01030', instrument_id: '10N01030' },
            { hos_pos_name: '10N07003', instrument_id: '10N07003' },
          ]
          resolve()
        }else{//远程请求机号并格式处理
          api.qc_hosipital_instruments({hospital_id: this.hospital_id}).then((res) => {
            if (res.code == 200) {
              this.optionsJh_all = res.data.data.map((e) => {
                return {
                  hos_pos_name: e.instrument_id ,
                  instrument_id: e.instrument_id,
                }
              })
            }
            resolve()
          }).catch(err=>{
            reject(err)
          })
        }
      });
    },
    /* 获取质控品数据 */
    get_qc_serum_info() {
      return new Promise((resolve,reject) => {
        api.qc_serum_info({ hospital_id: this.hospital_id }).then(res => {
          this.list_qcp_info_crude = res.data
          // 设置质控品管理里面需要的数据
          this.$refs.QCProductInfoDialog.set_tree_data_qcp(this.list_qcp_info_crude)
          // 设置机号和检测项目切换
          let qcInfoList_Jh = [], // 机号下包含项目
            qcInfoList_all = [] //所有质控品直接显示
          this.list_qcp_info_crude.forEach((temp) => {

            temp.qs_list.forEach((temp2) => {
              qcInfoList_all = qcInfoList_all.concat(temp2.qc_serum_info)
              let isNew = true
              qcInfoList_Jh.forEach((item) => {
                if (item.instrument_id == temp2.instrument_id) {
                  item.testingList.push({
                    testing_id: temp.testing_id,
                    testing_unique: temp.testing_unique,
                  })
                  isNew = false
                }
              })
              if (isNew) {
                qcInfoList_Jh.push({
                  hos_pos_name: temp2.hos_pos_name,
                  instrument_id: temp2.instrument_id,
                  label: temp2.label,
                  testingList: [
                    {
                      testing_id: temp.testing_id,
                      testing_unique: temp.testing_unique,
                    },
                  ],
                })
              }
            })
          })
          this.qcInfoList_all = qcInfoList_all
          this.qcInfoList_Jh = qcInfoList_Jh
          this.set_ph_list_crude();
          this.set_jhOption_and_xmOption('cs');
          resolve()
        }).catch(err=>{
          reject(err)
        })
      })
    },

    /* 主界面的均值显示处理 */
    handle_dataToTargetTable() {
      if(!this.list_qcp_info_crude){
        return
      }
      let temp = this.list_qcp_info_crude.find(e => e.testing_id == this.textTesting)
      if(temp && temp.qs_list){
        temp = temp.qs_list.find(e => e.instrument_id == this.textInstrument)
      }
      if(this.textqcBatch=='全部'){
        this.qc_data = temp.qc_serum_info
      }else{
        if(temp && temp.qc_serum_info){
          this.qc_data = temp.qc_serum_info.filter(e => e.qc_batch == this.textqcBatch)
        }
      }
      this.value_Bz = '0'
    },
    /* 删除了某质控品后刷新质控品 */

    delSucess_get_qc_serum(value) {
      this.loadingShow('删除中，请稍后');
      this.get_qc_serum_info().then(res => {
        this.open_QCProductInfo(
          {testing_id:value.testing_id,instrument_id:value.instrument_id}
        ) 
        this.$message.error('删除成功')
        this.loading.close();
      })
    },
    /* 质控品管理修改靶值数据后刷新质控品数据 */
    refresh_get_qc_serum(value) {
      this.loadingShow('保存中,请稍后');
      this.get_qc_serum_info().then(res => {
        this.open_QCProductInfo(
          {testing_id:value.testing_id,instrument_id:value.instrument_id}
        ) 
        // this.$refs.addQCProductDialog.close_dialog();
        this.$message.success('保存成功!')
        this.loading.close();
      })
    },
    /* 新增质控品后属性质控品 */
    addSuccess_get_qc_serum() {
      this.loadingShow('保存中,请稍后');
      this.get_qc_serum_info().then(res => {
        this.$refs.addQCProductDialog.close_dialog();
        this.$message.success('保存成功!')
        this.loading.close();
      })
    },
    /* 页面顶部下拉选择框中的数据处理 */
    // 设置机号和检测项目的可选择范围
    set_jhOption_and_xmOption(type='',value) {
      let that = this
      const set_jhOption = function(jh){
        if(jh){
          that.optionsJh = that.qcInfoList_Jh.filter(e=>e.instrument_id==jh).testingList
        }else{
          let def_instrument_id = that.default_qc_serum_id.split('-')[0]
          let current_instrument_id = ''
          if(['cs'].includes(type)){
            current_instrument_id = value ? value : def_instrument_id
          }else if(['xm'].includes(type)){
            current_instrument_id = that.valueJh
          }else{
            current_instrument_id = value
          }
          let list_qcp_info_crude_temp = JSON.parse(JSON.stringify(that.list_qcp_info_crude))
          list_qcp_info_crude_temp.forEach(item =>{
            if(item.qs_list){
              item.qs_list = item.qs_list.filter((ql) =>{ return ql.instrument_id == current_instrument_id})
            }
          })
          list_qcp_info_crude_temp = list_qcp_info_crude_temp.filter((lqict) =>{ return lqict.qs_list.length > 0})
          that.optionsXm = list_qcp_info_crude_temp.map(e=>{
            return {
              testing_id:e.testing_id,
              testing_unique:e.testing_unique,
            }
          })
        }
      }
      const set_xmOption = function(xm){
        if(xm){
          let temp_optionJh = that.list_qcp_info_crude.filter(e=>e.testing_id==xm)
          that.optionsJh = temp_optionJh.map(e=>{
            return {
              hos_pos_name: e.hos_pos_name,
              instrument_id:e.instrument_id,
              label:e.label,
            }
          })
        }else{
          that.optionsJh = that.qcInfoList_Jh.map(e=>{
            return {
              hos_pos_name: e.hos_pos_name,
              instrument_id:e.instrument_id,
              label:e.label,
            }
          })
        }
      }

      if(type == 'Jh') { //切换机号
        set_jhOption(value) //
      }else if(['Xm'].includes(type) ) { //切换项目
        set_xmOption(value)
      }else{ 
        set_jhOption('')
        set_xmOption('')
      }
      if(type == 'cs'){ // 其他页面跳转进来初始化
        if(that.optionsXm.filter(item=>{return  item.testing_unique == this.query.testing_unique}).length > 0){
          that.valueXm = that.optionsXm.filter(item=>{return item.testing_unique == this.query.testing_unique})[0].testing_id
        }
      }else if(type == 'xm'){
        that.valueXm = value
      }else{
        that.valueXm = that.optionsXm[0].testing_id
      }
    },
    // 设置质控品批号可筛选范围
    set_ph_list_crude(){
      if(this.valueJh&&this.valueXm){
        let ph_list_crude = this.list_qcp_info_crude.find(e=>e.testing_id==this.valueXm).qs_list.find(e=>e.instrument_id==this.valueJh).qc_serum_info
        this.ph_list_crude = ph_list_crude
        this.optionsPh = JSON.parse(JSON.stringify(ph_list_crude))
        ph_list_crude.length>1?this.optionsPh.unshift({qc_batch:'全部'}):''
        this.optionsPh.find(e=>e.qc_batch === this.valuePh)?"":this.valuePh = this.optionsPh[this.optionsPh.length-1].qc_batch
      }
    },
   
    /* 打开月度报告 */
    open_monthlyReport() {
      this.$refs.monthlyDialog.open_dialog(
        {
          testing_id: this.textTesting,
          instrument_id: this.textInstrument,
          nowDate: this.nowDate
        }
      );
    },
    /* 质控品设置相关操作 */
    // 下拉按钮列表
    open_QCProduct() {
      this.showQCProductBtnList = true;
      // 添加监听点击事件，点击后则下拉框消失
      this.$el.addEventListener('mousedown', ()=> {
        setTimeout(()=> {
          this.showQCProductBtnList = false;
        },500)
      },{once: true})
    },
    // 新增质控品弹窗
    open_addQCProducto() {
      this.$refs.addQCProductDialog.open_dialog();
    },
    // 质控品管理弹窗
    open_QCProductInfo(value=null) {
      if(!value){
        value = {testing_id:this.textTesting,instrument_id:this.textInstrument}
      }
      this.$refs.QCProductInfoDialog.open_dialog(value);
    },
    // 关闭质控品管理页面的回调
    refresh_data() {
      this.get_target_endtime_message();

      this.valueJh = this.textInstrument
      this.valueXm = this.textTesting
      this.valuePh = this.textqcBatch
      this.valueTime = [this.textTime.split('~')[0],this.textTime.split('~')[1]]
      this.set_jhOption_and_xmOption();
      this.set_ph_list_crude();

      let param = {
        hospital_id: this.hospital_id, //医院标识
        testing_id: this.textTesting, //检测项目标识
        instrument_id: this.textInstrument, //机号
        start_date: this.textTime.split('~')[0],
        end_date: this.textTime.split('~')[1],
      }
      if(this.textqcBatch != '全部') {
        param.qc_serum_id = `${this.textInstrument}-${this.textTesting}-${this.textqcBatch}`
      }
      this.loadingShow();
      this.get_screen_data(param).then(res => {
        this.handle_dataToTargetTable()
        this.loading.close(); 
      });
    },
    // 查看定靶数据
    open_qcpTargetDataDialog(qc_serum_id) {
      this.$refs.qcpTargetDataDialog.open_dialog(qc_serum_id);
    },
    // 定靶修改后的回调
    refresh_listAndtarget(value) {
      this.get_target_endtime_message();

      let param = {
        hospital_id: this.hospital_id, //医院标识
        testing_id: this.textTesting, //检测项目标识
        instrument_id: this.textInstrument, //机号
        start_date: this.textTime.split('~')[0],
        end_date: this.textTime.split('~')[1],
      }
      if(this.textqcBatch != '全部') {
        param.qc_serum_id = `${this.textInstrument}-${this.textTesting}-${this.textqcBatch}`
      }
      this.loadingShow('保存中，请稍后');
      Promise.all([
        this.get_qc_serum_info(),
        this.get_screen_data(param),
      ]).then(res => {
        this.handle_dataToTargetTable()
        this.loading.close();
        this.$refs.qcpTargetDataDialog.close_dialog();
        this.$message.success('保存成功!')
      })
    },
    /* 查看累计均值操作 */
    // 打开均值弹窗 并获取数据
    open_intervalDialog() {
      this.list_interval_target = [{
        name: '',
        c1_cnt: '',
        c2_cnt: '',
        target_c1: '',
        target_c2: '',
        sd_c1: '',
        sd_c2: '',
        cv_c1: '',
        cv_c2: '',
      }]
      this.valueIntervalPh = '0';
      this.dialogVisible_interval = true;
      setTimeout(() => {
        this.loadingShow('数据加载中，请等待')
      }, 100);
      let textTime_text_list = this.textTime.split('~')
      let param = {
        start_date: textTime_text_list[0],
        end_date: textTime_text_list[1],
      }
      if(this.textqcBatch=='全部') {
        
        let temp = this.list_qcp_info_crude.find(e => e.testing_id == this.textTesting)
        temp = temp.qs_list.find(e => e.instrument_id == this.textInstrument)
        param.qc_serum_list = temp.qc_serum_info.map(e => e.qc_serum_id)
      }else {
        param.qc_serum_list = [`${this.textInstrument}-${this.textTesting}-${this.textqcBatch}`]
      }
      api.qc_stats(param).then(res => {
        let temp_list = []
        res.data.grand_data.forEach(temp => {
          let item = {
            // name: temp.qc_serum_id.split('-')[2],
            // c1_cnt: temp.interval_c1_cnt,
            // c2_cnt: temp.interval_c2_cnt,
            // target_c1: temp.interval_fixed_target_c1,
            // target_c2: temp.interval_fixed_target_c2,
            // sd_c1: temp.interval_fixed_sd_c1,
            // sd_c2: temp.interval_fixed_sd_c2,
            // cv_c1: temp.interval_fixed_cv_c1,
            // cv_c2: temp.interval_fixed_cv_c2,
            name: temp.qc_serum_id.split('-')[2],
            c1_cnt: temp.interval_stats.c1_cnt,
            c2_cnt: temp.interval_stats.c2_cnt,
            target_c1: temp.interval_stats.target_c1,
            target_c2: temp.interval_stats.target_c2,
            sd_c1: temp.interval_stats.sd_c1,
            sd_c2: temp.interval_stats.sd_c2,
            cv_c1: temp.interval_stats.cv_c1,
            cv_c2: temp.interval_stats.cv_c2,
          }
          temp_list.push(item)
        })
        this.list_interval_target = temp_list
        this.loading.close(); 
      })
    },
    // 切换tab
    changeTabs() {
      
    },
   
    /* 图表组件产生数据变化时刷新当前页面数据 */
    change_data() {
      let param = {
        hospital_id: this.hospital_id, //医院标识
        testing_id: this.textTesting, //检测项目标识
        instrument_id: this.textInstrument, //机号
        start_date: this.textTime.split('~')[0],
        end_date: this.textTime.split('~')[1],
      }
      if(this.textqcBatch != '全部') {
        param.qc_serum_id = `${this.textInstrument}-${this.textTesting}-${this.textqcBatch}`
      }
      this.loadingShow() 
      this.get_screen_data(param).then(res => {
        this.handle_dataToTargetTable()
        this.loading.close();
      })
    },
    /* 确认搜索 */
    confirm_screen() {
      let param = {
        hospital_id: this.hospital_id, //医院标识
        testing_id: this.valueXm, //检测项目标识
        instrument_id: this.valueJh, //机号
      }
      let start_date='',end_date='',qc_serum_list=[];
      if(this.valuePh === '全部'){  //全部时必须去选择时间
        if(this.valueTime&&this.valueTime[0]!=''){
          start_date = this.valueTime[0]
          end_date = this.valueTime[1]
          this.optionsPh.forEach(e=>{
            if(e.qc_batch!='全部'){
              qc_serum_list.push(e.qc_serum_id)
            }
          })
        }else {
          this.$message.error(this.valueTimeType===2?'请选择开始时间和结束时间':'请选择月份')
          return
        }
      }else {

        let temp = this.optionsPh.find(e=>e.qc_batch==this.valuePh)
        param.qc_serum_id = temp.qc_serum_id;  //单批号则在此加字段
        qc_serum_list = [temp.qc_serum_id]
        if(this.valueTime==null||this.valueTime[0]===''){ 
          // 无时间
          if(this.compareDate(temp.end_date,temp.start_date)){
            start_date = temp.start_date;
            end_date = temp.end_date;
          }else {
            start_date = temp.end_date;
            end_date = temp.end_date;
          }
        }else {
          // 有时间
          start_date = this.valueTime[0]
          end_date = this.valueTime[1]
        }
      }
      param = {
        ...param,
        start_date,
        end_date
      }
      this.loadingShow() 

      this.get_screen_data(param).then(res => {
        this.handle_dataToTargetTable()
        this.loading.close();
      })
    },
    /* 获取搜索数据 */
    get_screen_data(param) {
      return new Promise((resolve, reject) => {
        this.textInstrument = ''
        this.textTesting =  ''
        this.textqcBatch = ''
        this.textTime = ''
        this.showIntervalBtn = false
        this.tableAndChartData = {
          qc_list: [],
          qc_data: {},
          qc_serum_dict: {},
        }
        this.qc_data= [
          {
            qc_batch: '',
            is_using_fixed: true,
            target_c1: '',
            sd_c1: '',
            cv_c1: '',
            target_c2: '',
            sd_c2: '',
            cv_c2:'',
            stats: {},
          }
        ];

        this.push_tableAndChart_data();
        api.screen_qc_data(param).then(res => {
          // 设置 已选条件文字描述
          this.textInstrument = param.instrument_id
          this.textTesting =  param.testing_id
          this.textqcBatch = param.qc_serum_id?param.qc_serum_id.split('-')[2]:'全部'
          this.textTime = `${param.start_date}~${param.end_date}`
          this.showIntervalBtn = res.data.qc_list.length!=0
          // 列表数据设置
          this.tableAndChartData = res.data
          this.push_tableAndChart_data();
          resolve()
        }).catch(err => {
          resolve()
        })
      })
    },
   
    /* 设置默认机号和检测项目 */
    set_default() {
      let param = {
        instrument_id: this.valueJh,
        testing_id: this.valueXm,
        is_setting_batch: 0
      }
      api.set_default_qc_bain({
        ...param,
        hospital_id: this.hospital_id,
      }).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg)
        } else {
          this.$message.error('操作失败！')
        }
      })
    },
    /* 各搜索项切换后的逻辑处理 开始*/
    // 换机号
    changeJh() {
      this.set_jhOption_and_xmOption('jh',this.valueJh)
      // this.setTime_$timePickerCloud({
      //   start_stats_day: '',
      //   end_stats_day: '',
      // });
      this.valueMonth = ''
      this.valueTime = ['','']
      this.set_ph_list_crude();
    },
    // 换项目
    changeXm() {
      this.set_jhOption_and_xmOption('xm',this.valueXm)
      // this.setTime_$timePickerCloud({
      //     start_stats_day: '',
      //     end_stats_day: '',
      //   });
      this.valueMonth = ''
      this.valueTime = ['','']
      this.set_ph_list_crude();
    },
    // 换批号
    changePh() {
      // this.setTime_$timePickerCloud({
      //   start_stats_day: '',
      //   end_stats_day: '',
      // });
      this.valueMonth = ''
      this.valueTime = ['','']

    },
    // 换日期类型
    changeTimeType() {
      if(this.valueTimeType === 2){
        this.valueMonth = '';
        var date1 = new Date();
        var date2 = new Date(date1);
        //-30为30天前，+30可以获得30天后的日期
        date2.setDate(date1.getDate() - 30);
        var agoDay = `${date2.getFullYear()}-${date2.getMonth() + 1<10?`0${date2.getMonth() + 1}`:date2.getMonth() + 1}-${date2.getDate()}`;
        this.valueTime = [agoDay,this.nowDate]
        
      }else{
        this.valueTime = ['','']
        let nowDate_text_list = this.nowDate.split('-')
        this.valueMonth = `${nowDate_text_list[0]}-${nowDate_text_list[1]}`
        this.chanageMonth(this.valueMonth)
      }
    },
    // 切换月份
    chanageMonth(val) {
      if(val){
        let timeList = val.split('-') //选择的时间
        let d = new Date(timeList[0], Number(timeList[1]), 0).getDate();
        let endData = timeList.join('-') + '-'+d
        this.valueTime[0] = timeList.join('-')+'-01'
        this.valueTime[1] =  this.compareDate(endData,this.nowDate)?this.nowDate:endData
      }else {
        this.valueTime[0] = ''
        this.valueTime[1] = ''
      }
    },
    /* 各搜索项切换后的逻辑处理 结束*/
    /*  设置时间 尅是*/
    // 组件时间传回
     setTime(val) {
      this.start_stats_day = val.start_stats_day?val.start_stats_day.substring(0,4)+'-'+ 
                              val.start_stats_day.substring(4,6)+'-'+ 
                              val.start_stats_day.substring(6,8):'';
      this.end_stats_day =  val.end_stats_day?val.end_stats_day.substring(0,4)+'-'+
                            val.end_stats_day.substring(4,6)+'-'+
                            val.end_stats_day.substring(6,8):'';
    },
    //给组件设置时间及给自己设置时间 
    setTime_$timePickerCloud(val) {
      this.start_stats_day = val.start_stats_day?val.start_stats_day.substring(0,4)+'-'+ 
                              val.start_stats_day.substring(4,6)+'-'+ 
                              val.start_stats_day.substring(6,8):'';
      this.end_stats_day =  val.end_stats_day?val.end_stats_day.substring(0,4)+'-'+
                            val.end_stats_day.substring(4,6)+'-'+
                            val.end_stats_day.substring(6,8):'';
      this.$refs.timePickerCloud.setTime(val.start_stats_day,val.end_stats_day)
      if(!val.start_stats_day){
        this.valueMonth = '';
      }
    },
    /*  设置时间 结束*/
    
  }
}
</script>

<style lang="scss" scoped>
  @mixin box-border() {
    margin: $gap;
    background-color: #fff;
    padding: $gap;
    border-radius: $gap;
  }
  .indoor-box {
    font-size: $text-size;
  }
  .indoor-content {
    //height: 500px;
    height: calc(100vh - 56px);
    overflow: auto;
  }
  .titleText {
    font-weight: bold;
    color: #111111;
    margin-left: $gap;
    margin-right: $gap;
    font-size: $title-size;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .search-box {
    display: flex;
    align-items: center;
    gap: $gap;
    @include box-border;
    padding: 2*$gap $gap;
    li { 
      height: 100%;
      display: flex;
      align-items: center;
    }
    .li-datePick-box {
      ::v-deep .el-input__inner {
        width: inherit;
      }
    }
    .li-timePick-box {
      ::v-deep .calendarDate {
        width: px(350);
      }
    }
  }
  .endtime-box {
    height: px(40);
    @include box-border;
    background-color: #FCFCE9;
    .endtime-item-box {
      height: px(40);
      display: flex;
      align-items: center;
      gap: 3*$gap;
      &>p {
        font-weight: $text-size;
        font-weight: bold;
        span {
          color: red;
        }
      }
    }
  }
  .function-box {
    display: flex;
    flex-wrap: wrap;
    gap: $gap;
    @include box-border;
    .function-btn-box {
      width: 100%;
      text-align: right;
    }
    .function-body-box {
      width: 100%;
      display: flex;
      gap: 2*$gap;
      .left {
        padding: $gap;
        font-size: $text-size;
        border: $border-color solid 1px;
        border-radius: $gap/2;
        flex: 1;
        display: flex;
        font-size: $text-size;
        align-items: center;
        &>div {
          flex: 4;
          display: grid;
          align-items: center;
          justify-content: space-between;
          p:first-child {
            width: 30%;
            min-width: px(120);
            padding-bottom: $gap/2;
            border-bottom: 1px solid $border-color;
          }
          p:last-child {
            margin-top: 2*$gap;
            display: flex;
            gap: $gap;
            &>span {
              font-size: $text-size;
              line-height: $text-size;;
            }

          }
        }
       
       
      }
      .right {
        flex: 1;
        display: flex;
        &>div:first-child {
          flex: 1;
        }
        &>div:last-child {
          flex: 9;
        }
        .table-box {
          border-radius: $gap/2;
          overflow: hidden;
          border: 1px solid $border-color;
        }
        .target-table{
          width:100%;
          height: 100%;
          border-spacing: 0;
          border-collapse: collapse;
          border-color:  $border-color;
          border-style: solid;
          border: 0;
          .target-tbody{
            
            .th {
              font-size: $table-th-size;
            }
            .data-th {
              width: 13%;
            }
            tr {
              width: 100%;
              text-align: center;

            }
            tr:first-child {
              td {
                border-top: 0;
              }
            }
            .no-left-border {
              border-left: 0;
            }
            .no-bottom-border {
              border-bottom: 0;
            }
            .no-right-border {
              border-right: 0;
            }
            tr:last-child {
              td {
                border-bottom: 0;
              }
            }
          }
          .target-btn-box {
            &>P {
              font-size: $text-size;
            }
          }
        }
      }
    }
   
  }
  .QCProduct-btn {
    position: relative;
    .QCProduct-btnbox {
      position: absolute;
      width: 100%;
      background-color: #fff;
      left: 0;
      bottom: -px(70);
      display: flex;
      flex-wrap: wrap;
      height: px(70);
      &>button {
        width: 100%;
      }
      button:last-child {
        margin-left: 0;
      }
    }
  } 
  
  .btn {
    //font-size: $btn-text-size;
  }

  //中间弹窗
  ::v-deep .dialog_center {
    height: px(600);
    .el-dialog__header {
      height: 0;
      padding: 1px;
    }
    .el-dialog__body {
      margin: $gap;
      padding: $gap;
    }
  }
  .dialog-center-box {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: $dialog-title-size;
      border-bottom: $border-color solid 1px;
      p {
        font-weight: bold;
        color: #111111;
        font-size: $dialog-title-size;
        padding-bottom: $gap;
        width: 20%;
      }
    }
    .body {
      margin: $gap;
      font-size: $dialog-text-size;
    }
  }

  .interval-box {
    .th {
      font-size: $table-th-size;
      font-weight: bold;
    }
    th {
      width: 20%;
      padding: $gap;
    }
    td {
      padding: $gap;
    }
    .body {
      height: px(450);
      
      .interval_table {
        margin-top: 2*$gap;
      }
    }
    .footed {
      display: flex;
      justify-content: center;
    }
  }
  .indoor-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border-color: $border-color;
    tr {
      width: 100%;
      text-align: center;
    }
    th {
      font-size: $table-th-size;
    }
  
    td {
      font-size: $table-td-size;
    }
  }
  .xbar {
    text-decoration: overline;
  }
  
</style>