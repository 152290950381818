export default {
  data() {
    return {
      loading: '',
      optionsXm: [{testing_id:4,testing_unique:'hs-CRP'},{testing_id:11,testing_unique:'SAA'}],//项目列表
      lineStyleList: [
        {
          type: 'solid'
        },
        {
          type: [3, 10],
        },
        {
          type: [10, 3],
        },
        {
          type: [4, 10],
        },
        {
          type: [10, 4],
        },
        {
          type: [5, 10],
        },
        {
          type: [10, 5],
        },
        {
          type: [6, 10],
        },
        {
          type: [10, 6],
        },
        
      ],
      list_qcp_rule: [
        {
          text: '1-2S',
          field: 'rule_1_2S',
          rule_description: '单个质控点高于 (均值+2SD) 或低于 (均值-2SD)',
          radioGJ: false,
          radioSK: false,
        },
        {
          text: '1-2.5S',
          field: 'rule_1_25S',
          rule_description: '单个质控点高于 (均值+2.5SD) 或低于 (均值-2.5SD)',
          radioGJ: false,
          radioSK: false,
        },
        {
          text: '1-3S',
          field: 'rule_1_3S',
          rule_description: '单个质控点高于 (均值+3SD) 或低于 (均值-3SD)',
          radioGJ: false,
          radioSK: false,
        },
        {
          text: '2-2S',
          field: 'rule_2_2S',
          rule_description: '连续2个质控点高于 (均值+2SD) 或低于 (均值-2SD)',
          radioGJ: false,
          radioSK: false,
        },
        {
          text: '3-1S',
          field: 'rule_3_1S',
          rule_description: '连续3个质控点高于 (均值+SD) 或低于 (均值-SD)',
          radioGJ: false,
          radioSK: false,
        },
        {
          text: '4-1S',
          field: 'rule_4_1S',
          rule_description: '连续4个质控点高于 (均值+SD) 或低于 (均值-SD)',
          radioGJ: false,
          radioSK: false,
        },
        {
          text: '6-X',
          field: 'rule_6_X',
          rule_description: '连续6个质控点高于 (均值) 或低于 (均值)',
          radioGJ: false,
          radioSK: false,
        },
        {
          text: '8-X',
          field: 'rule_8_X',
          rule_description: '连续8个质控点高于 (均值) 或低于 (均值)',
          radioGJ: false,
          radioSK: false,
        },
        {
          text: '10-X',
          field: 'rule_10_X',
          rule_description: '连续10个质控点高于 (均值) 或低于 (均值)',
          radioGJ: false,
          radioSK: false,
        },
        {
          text: '12-X',
          field: 'rule_12_X',
          rule_description: '连续12个质控点高于 (均值) 或低于 (均值)',
          radioGJ: false,
          radioSK: false,
        },
      ],
    }
  },
  computed: {
    textTesting_unique(){
      return (testing) =>{
        return this.optionsXm.find(e => e.testing_id ==testing)?.testing_unique
      }
    },
    textTarget() {
      return (text)=> {
        return text!==''&&text!==null&&text!==undefined?Number(text).toFixed(2):'-'
      }
    },
    textCV() {
      return (text)=> {
        return text!==''&&text!==null&&text!==undefined?(Number(text)*100).toFixed(2):'-'
      }
    }
  },
  methods: {
    /* 将后端返回的数据处理成Z分数图和LJ图能展示的数据 */
    handle_dataToEcharts(qc_data,qc_list,qc_serum_dict) {
      let dayIndex = 1; // 每天次数计算
      // Z分数图
      let zData = {
        text: 'z分数图',
        dataX:'',
        series: [],
        name: [],
      }
      let dataX = [];
      // 定义有多少条线
      let arr = {};
      const qc_serum_id_list = Object.keys(qc_serum_dict)
      qc_serum_id_list.forEach(key => {
        arr[key+'_1'] = [];
        arr[key+'_2'] = []; 
      })

      if(qc_serum_id_list.length ==1){
        // 单批号情况下
        qc_list.forEach(e => {
          let date_time_order_textList = e.date_time_order.split('.')
          // 新的天数将dayIndex置为1
          if(date_time_order_textList[1]=='1'){
            dayIndex = 1;
          }
          // c1c2至少有一个为接受
          if(e.c1_accept||e.c2_accept){
            dataX.push(date_time_order_textList[0]+'.'+dayIndex)
            for(let key in arr){
              let key_text_list = key.split('_')
              if(key_text_list[0] == e.qc_serum_id&&e['c'+key_text_list[1]+'_accept']){
                arr[key].push({
                  value: e['normalized_c'+key_text_list[1]] < -3.8
                    ? -3.8
                    : e['normalized_c'+key_text_list[1]] >3.8
                    ? 3.8
                    : e['normalized_c'+key_text_list[1]],
                  realValue: e['c'+key_text_list[1]],
                  batch: e.batch,
                  cl: e['is_c'+key_text_list[1]+'_lc'],
                  label: 'C'+key_text_list[1],
                  rule: e['c'+key_text_list[1]+'_rule']
                })
              }else {
                arr[key].push({
                  value: null,
                  realValue: null,
                  cl: null,
                  label: 'C'+key_text_list[1],
                  rule: null,
                })
              }
            }
            dayIndex++
          }
        })
      }else {
        // 多批号情况下
      qc_list.forEach(e=> {
        dataX.push(e.date_time_order)
      })
      dataX.forEach(e=>{
        qc_serum_id_list.forEach(key => {
          let qc_instance = qc_serum_dict[key].find(element => e == element.date_time_order)
          if(qc_instance){
            arr[key+'_1'].push({
              value: qc_instance.normalized_c1 < -3.8
                ? -3.8
                : qc_instance.normalized_c1 >3.8
                ? 3.8
                : qc_instance.normalized_c1,
              realValue: qc_instance.c1,
              cl: qc_instance.is_c1_lc,
              batch: qc_instance.batch,
              accept: qc_instance.c1_accept,
              report: qc_instance.c1_report,
              rule: qc_instance.c1_rule,
              id: qc_instance.c1_id,
              label: 'C1',
              qc_instance_id: qc_instance.qc_instance_id,
            })
            arr[key+'_2'].push({
              value: qc_instance.normalized_c2 < -3.8
                ? -3.8
                : qc_instance.normalized_c2 >3.8
                ? 3.8
                : qc_instance.normalized_c2,
              realValue: qc_instance.c2,
              cl:qc_instance.is_c2_lc ,
              rule: qc_instance.c2_rule,
              batch: qc_instance.batch,
              accept: qc_instance.c2_accept,
              report: qc_instance.c2_report,
              id: qc_instance.c2_id,
              label: 'C2',
              qc_instance_id: qc_instance.qc_instance_id,
            })
          } else {
            arr[key+'_1'].push({
              value: null,
              realValue: null,
              cl: null,
              batch: '',
            })
            arr[key+'_2'].push({
              value: null,
              realValue: null,
              cl: null,
              batch: '',
            })
          }
        })
      })
      }
      
      
      

     
      let index = 0
      for(let key in arr){
        let key_text_list = key.split('_')
        zData.series.push({
          name: `${key_text_list[0].split('-')[2]} C${key_text_list[1]}质控点`,
          type: 'line',
          symbol: 'rect',
          symbolSize: 11,
          color: key_text_list[1]==1?'#166DC9':'#7CCE7A',
          lineStyle: this.lineStyleList[parseInt(index/2)],
          itemStyle: {
            color: (obj) => {
              if (obj.data.cl == true) {
                return 'red'
              } else if (obj.data.cl == false) {
                return '#F59F34'
              }else {
                return obj.color
              }
            },
          },
          connectNulls: true,
          data: arr[key],
        })
        zData.name.push(`${key_text_list[0].split('-')[2]} C${key_text_list[1]}质控点`)
        index++
      }
      zData.dataX = dataX;
      this.zData = zData
      // LJ图
      let ljData = []
      qc_serum_id_list.forEach(key => {
        const key_text_list = key.split('-')
        let lj_arr1 = [];
        let lj_arr2 = [];
        let time_arr = [];
        // 计算出当前批号的最大最小值
        let qc_serum_item = qc_data.find(item=>item.qc_serum_id===key)
        let max_c1 = qc_serum_item.target_c1 + 3.8*qc_serum_item.sd_c1,
            min_c1 = qc_serum_item.target_c1 - 3.8*qc_serum_item.sd_c1,
            max_c2 = qc_serum_item.target_c2 + 3.8*qc_serum_item.sd_c2,
            min_c2 = qc_serum_item.target_c2 - 3.8*qc_serum_item.sd_c2;
        qc_serum_dict[key].forEach(e => {
          let date_time_order_textList = e.date_time_order.split('.')
          // 新的天数将dayIndex置为1
          if(date_time_order_textList[1]=='1'){
            dayIndex = 1;
          }
          // c1c2至少有一个为接受
          if(e.c1_accept||e.c2_accept){
            time_arr.push(date_time_order_textList[0]+'.'+dayIndex)
            if(e.c1_accept){
              lj_arr1.push({
                value: e.c1<min_c1
                  ?min_c1
                  :e.c1>max_c1
                  ?max_c1:e.c1,
                realValue: e.c1,
                batch: e.batch,
                cl:e.is_c1_lc,
                rule: e.c1_rule,
                label: 'C1',
              })
            }else {
              lj_arr1.push({
                value: null,
                realValue: null,
                cl: null,
                rule: null,
                label: 'C1',
              })
            }
            if(e.c2_accept){
              lj_arr2.push({
                value: e.c2<min_c2
                  ?min_c2
                  :e.c2>max_c2
                  ?max_c2:e.c2,
                batch: e.batch,
                realValue: e.c2,
                cl:e.is_c2_lc,
                rule: e.c2_rule,
                label: 'C2',
              })
            }else {
              lj_arr2.push({
                value: null,
                realValue: null,
                cl: null,
                rule: null,
                label: 'C2',
              })
            }
            dayIndex++
          }
        })
        ljData.push( {
          c1_data: {
            id: key_text_list[2] + ' C1质控点',
            data: {
              name: key_text_list[2] +' C1质控点',
              type: 'line',
              symbol: 'rect',
              symbolSize: 11,
              color: '#166DC9',
              itemStyle: {
                color: (obj) => {
                  if (obj.data.cl == true) {
                    return 'red'
                  } else if (obj.data.cl == false) {
                    return '#F59F34'
                  }else {
                    return obj.color
                  }
                },
              },
              connectNulls: true,
              data: lj_arr1,
            },
            dataX: time_arr,
            sd: qc_serum_item.sd_c1  ,
            target: qc_serum_item.target_c1,
          },
          c2_data: {
            id: key_text_list[2] + ' C2质控点',
            data: {
              name: key_text_list[2] +' C2质控点',
              type: 'line',
              symbol: 'rect',
              symbolSize: 11,
              color: '#7CCE7A',
              itemStyle: {
                color: (obj) => {
                  if (obj.data.cl == true) {
                    return 'red'
                  } else if (obj.data.cl == false) {
                    return '#F59F34'
                  }else {
                    return obj.color
                  }
                },
              },
              connectNulls: true,
              data: lj_arr2,
            },
            dataX: time_arr,
            sd: qc_serum_item.sd_c2  ,
            target: qc_serum_item.target_c2,
          },
        })
      })
      return {
        zData,
        ljData
      }
    },
    // 提示弹窗
    loadingShow(text='数据加载中，请稍后') {
      this.loading = this.$loading({
        lock: true,
        text,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
    },
    // 比较时间大小
    compareDate(dateTime1,dateTime2){
      var formatDate1 = new Date(dateTime1)
      var formatDate2 = new Date(dateTime2)
      return formatDate1 > formatDate2
    }
  },
}