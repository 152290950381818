<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      custom-class="dialog_right"
      :show-close="false"
    >
      <div class="add-box">
        <div class="top">
          <p>新增质控品</p>
          <i class="el-icon-close" @click="close_dialog"></i>
        </div>
        <div class="body">
          <div class="step-box">
            <p></p>
            <div :class="getstepBox(0)">
              <p></p>
              <span>填写基本信息</span>
            </div>
            <p></p>
            <div :class="getstepBox(1)">
              <p></p>
              <span>设置质控号</span>
            </div>
            <p></p>
            <div :class="getstepBox(2)">
              <p></p>
              <span>设置均值</span>
            </div>
            <p></p>
            <div :class="getstepBox(3)">
              <p></p>
              <span>设置质控规则</span>
            </div>
            <p></p>
          </div>
          <div class="content">
            <div v-show="stepStatus === 0" class="qcp-info-box">
              <!-- 填写基本信息 -->
              <ul>
                <li>
                  <p>机号:</p>
                  <p>
                    <el-select
                      v-model="data_qcp_info.instrument_id"
                      placeholder="请选择"
                      size="mini"
                      multiple
                      class="inpuStyle multiple-input"
                    >
                      <el-option
                        v-for="item in optionsJh_all"
                        :key="item.instrument_id"
                        :label="item.instrument_id"
                        :value="item.instrument_id"
                      ></el-option>
                    </el-select>
                  </p>
                </li>
                <li>
                  <p>检测项目:</p>
                  <p>
                    <el-select
                      v-model="data_qcp_info.testing_id"
                      placeholder="请选择"
                      size="mini"
                      multiple
                      class="inpuStyle multiple-input"
                    >
                      <el-option
                        v-for="item in optionsXm"
                        :key="item.testing_id"
                        :label="item.testing_unique"
                        :value="item.testing_id"
                      ></el-option>
                    </el-select>
                  </p>
                </li>
                <li>
                  <p>浓度个数:</p>
                  <p>{{ data_qcp_info.valueNd }}</p>
                  <!-- <p>
                    <el-input
                      placeholder="请输入质控品名称"
                      v-model="data_qcp_info.valueNd"
                      size="mini"
                      class="inpuStyle"
                      :disabled="true"
                    ></el-input>
                  </p> -->
                </li>
                <li>
                  <p>质控品名称:</p>
                  <p>
                    <el-input
                      placeholder="请输入质控品名称"
                      v-model="data_qcp_info.qc_serum_name"
                      size="mini"
                      class="inpuStyle"
                    ></el-input>
                  </p>
                </li>
                <li>
                  <p>质控品批号:</p>
                  <p>
                    <el-input
                      placeholder="请输入质控品批号"
                      v-model="data_qcp_info.qc_batch"
                      size="mini"
                      class="inpuStyle"
                    ></el-input>
                  </p>
                </li>
                <li>
                  <p>质控品来源:</p>
                  <p>
                    <el-select
                      v-model="data_qcp_info.serum_source"
                      placeholder="请选择"
                      size="mini"
                    >
                      <el-option
                        v-for="item in optionsLy"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </p>
                </li>
                <li>
                  <p>启用日期:</p>
                  <p>
                    <el-date-picker
                      v-model="data_qcp_info.start_date"
                      type="date"
                      placeholder="选择日期"
                      size="mini"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>
                  </p>
                </li>
              </ul>
            </div>
            <div v-show="stepStatus === 1" class="qcp-num-box">
              <!-- 填写质控号 -->
              <table border="1" class="qcp-table">
                <thead class="qcp-thead">
                  <tr>
                    <th>机号</th>
                    <th>检测项目</th>
                    <th>水平</th>
                    <th>质控页面</th>
                    <th>样本页面</th>
                  </tr>
                </thead>
                <tbody
                  class="qcp-tbody"
                  v-for="(item, index) in list_qcp_num"
                  :key="index"
                >
                  <tr>
                    <td :rowspan="item.instrumentRowspan">
                      {{ item.instrumentText }}
                    </td>
                    <td rowspan="2">{{ item.testingList[0].testingText }}</td>
                    <td>C1</td>
                    <td>
                      <el-input
                        placeholder=""
                        v-model="
                          item.testingList[0].qc_number_data.qc_number_of_c1
                        "
                        size="small"
                        class="inpuStyle"
                      ></el-input>
                    </td>
                    <td>
                      <el-input
                        placeholder=""
                        v-model="
                          item.testingList[0].qc_number_data
                            .qc_sample_number_of_c1
                        "
                        size="small"
                        class="inpuStyle"
                      ></el-input>
                    </td>
                  </tr>
                  <tr>
                    <td>C2</td>
                    <td>
                      <el-input
                        placeholder=""
                        v-model="
                          item.testingList[0].qc_number_data.qc_number_of_c2
                        "
                        size="small"
                        class="inpuStyle"
                      ></el-input>
                    </td>
                    <td>
                      <el-input
                        placeholder=""
                        v-model="
                          item.testingList[0].qc_number_data
                            .qc_sample_number_of_c2
                        "
                        size="small"
                        class="inpuStyle"
                      ></el-input>
                    </td>
                  </tr>
                  <tr v-if="item.testingList.length > 1">
                    <td rowspan="2">{{ item.testingList[1].testingText }}</td>
                    <td>C1</td>
                    <td>
                      <el-input
                        placeholder=""
                        v-model="item.testingList[1].qc_number_of_c1"
                        size="small"
                        class="inpuStyle"
                      ></el-input>
                    </td>
                    <td>
                      <el-input
                        placeholder=""
                        v-model="item.testingList[1].qc_sample_number_of_c1"
                        size="small"
                        class="inpuStyle"
                      ></el-input>
                    </td>
                  </tr>
                  <tr v-if="item.testingList.length > 1">
                    <td>C2</td>
                    <td>
                      <el-input
                        placeholder=""
                        v-model="item.testingList[1].qc_number_of_c2"
                        size="small"
                        class="inpuStyle"
                      ></el-input>
                    </td>
                    <td>
                      <el-input
                        placeholder=""
                        v-model="item.testingList[1].qc_sample_number_of_c2"
                        size="small"
                        class="inpuStyle"
                      ></el-input>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="tip">
                <span>注</span>
                ：“质控页面”和“样本页面”至少需填写一组C1、C2。
              </p>
            </div>
            <div v-show="stepStatus === 2" class="qcp-target-box">
              <!-- 设置均值 -->
              <table border="1" class="qcp-table">
                <thead class="qcp-thead">
                  <tr>
                    <th>机号</th>
                    <th>检测项目</th>
                    <th>均值选项</th>
                    <th>水平</th>
                    <th>均值设置</th>
                  </tr>
                </thead>
                <tbody
                  class="qcp-tbody"
                  v-for="(item, index) in list_qcp_target"
                  :key="index"
                >
                  <tr>
                    <td :rowspan="item.instrumentRowspan">
                      {{ item.instrumentText }}
                    </td>
                    <td :rowspan="item.testingList[0].is_using_fixed ? 2 : 1">
                      {{ item.testingList[0].testingText }}
                    </td>
                    <td :rowspan="item.testingList[0].is_using_fixed ? 2 : 1">
                      <div class="qcp-target-radio-box">
                        <el-radio
                          class="qcp-target-radio"
                          v-model="item.testingList[0].is_using_fixed"
                          :label="true"
                        >
                          设定靶值
                        </el-radio>
                        <el-radio
                          class="qcp-target-radio"
                          v-model="item.testingList[0].is_using_fixed"
                          :label="false"
                        >
                          浮动靶值
                        </el-radio>
                      </div>
                    </td>
                    <td v-show="item.testingList[0].is_using_fixed">C1</td>
                    <td
                      v-show="item.testingList[0].is_using_fixed"
                      class="input-td"
                    >
                      <ul>
                        <li>
                          <p>均值:</p>
                          <p>
                            <el-input
                              type="number"
                              min="0"
                              placeholder="请输入均值"
                              v-model="
                                item.testingList[0].fixed_info.fixed_target_c1
                              "
                              size="mini"
                              class="inpuStyle"
                            ></el-input>
                          </p>
                        </li>
                        <li>
                          <p>标准差:</p>
                          <p>
                            <el-input
                              type="number"
                              min="0"
                              placeholder="请输入标准差"
                              v-model="
                                item.testingList[0].fixed_info.fixed_sd_c1
                              "
                              size="mini"
                              class="inpuStyle"
                            ></el-input>
                          </p>
                        </li>
                      </ul>
                    </td>

                    <td v-show="!item.testingList[0].is_using_fixed">C1/C2</td>
                    <td
                      v-show="!item.testingList[0].is_using_fixed"
                      class="input-td"
                    >
                      <el-input
                        type="number"
                        min="2"
                        placeholder="请输入定靶次数"
                        v-model="item.testingList[0].setting_target_times"
                        size="mini"
                        class="target-num-input"
                      ></el-input>
                    </td>
                  </tr>
                  <tr v-show="item.testingList[0].is_using_fixed">
                    <td>C2</td>
                    <td class="input-td">
                      <ul>
                        <li>
                          <p>均值:</p>
                          <p>
                            <el-input
                              type="number"
                              min="0"
                              placeholder="请输入均值"
                              v-model="
                                item.testingList[0].fixed_info.fixed_target_c2
                              "
                              size="mini"
                              class="inpuStyle"
                            ></el-input>
                          </p>
                        </li>
                        <li>
                          <p>标准差:</p>
                          <p>
                            <el-input
                              type="number"
                              min="0"
                              placeholder="请输入标准差"
                              v-model="
                                item.testingList[0].fixed_info.fixed_sd_c2
                              "
                              size="mini"
                              class="inpuStyle"
                            ></el-input>
                          </p>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr v-if="item.testingList.length > 1">
                    <td :rowspan="item.testingList[1].is_using_fixed ? 2 : 1">
                      {{ item.testingList[1].testingText }}
                    </td>
                    <td :rowspan="item.testingList[1].is_using_fixed ? 2 : 1">
                      <div class="qcp-target-radio-box">
                        <el-radio
                          class="qcp-target-radio"
                          v-model="item.testingList[1].is_using_fixed"
                          :label="true"
                        >
                          设定靶值
                        </el-radio>
                        <el-radio
                          class="qcp-target-radio"
                          v-model="item.testingList[1].is_using_fixed"
                          :label="false"
                        >
                          浮动靶值
                        </el-radio>
                      </div>
                    </td>
                    <td v-show="item.testingList[1].is_using_fixed">C1</td>
                    <td
                      v-show="item.testingList[1].is_using_fixed"
                      class="input-td"
                    >
                      <ul>
                        <li>
                          <p>均值:</p>
                          <p>
                            <el-input
                              type="number"
                              min="0"
                              placeholder="请输入均值"
                              v-model="
                                item.testingList[1].fixed_info.fixed_target_c1
                              "
                              size="mini"
                              class="inpuStyle"
                            ></el-input>
                          </p>
                        </li>
                        <li>
                          <p>标准差:</p>
                          <p>
                            <el-input
                              type="number"
                              min="0"
                              placeholder="请输入标准差"
                              v-model="
                                item.testingList[1].fixed_info.fixed_sd_c1
                              "
                              size="mini"
                              class="inpuStyle"
                            ></el-input>
                          </p>
                        </li>
                      </ul>
                    </td>
                    <td v-show="!item.testingList[1].is_using_fixed">C1/C2</td>
                    <td
                      v-show="!item.testingList[1].is_using_fixed"
                      class="input-td"
                    >
                      <el-input
                        type="number"
                        min="2"
                        placeholder="请输入定靶次数"
                        v-model="item.testingList[1].setting_target_times"
                        size="mini"
                        class="target-num-input"
                      ></el-input>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      item.testingList.length > 1 &&
                      item.testingList[1].is_using_fixed
                    "
                  >
                    <td>C2</td>
                    <td class="input-td">
                      <ul>
                        <li>
                          <p>均值:</p>
                          <p>
                            <el-input
                              type="number"
                              min="0"
                              placeholder="请输入均值"
                              v-model="
                                item.testingList[1].fixed_info.fixed_target_c2
                              "
                              size="mini"
                              class="inpuStyle"
                            ></el-input>
                          </p>
                        </li>
                        <li>
                          <p>标准差:</p>
                          <p>
                            <el-input
                              type="number"
                              min="0"
                              placeholder="请输入标准差"
                              v-model="
                                item.testingList[1].fixed_info.fixed_sd_c2
                              "
                              size="mini"
                              class="inpuStyle"
                            ></el-input>
                          </p>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-show="stepStatus === 3" class="qcp-rule-box">
              <!-- 设置质控规则 -->
              <table border="1" class="qcp-table qcp-rule-table">
                <thead class="qcp-thead">
                  <tr>
                    <th></th>
                    <th class="GJ">告警</th>
                    <th class="SK">失控</th>
                  </tr>
                </thead>
                <tbody class="qcp-tbody">
                  <tr v-for="(item, index) in list_setRule" :key="index">
                    <td>
                      <div class="rule-box">
                        <p>
                          {{ item.text }}
                        </p>
                        <el-popover
                          placement="top-start"
                          width="116"
                          trigger="hover"
                          :content="item.rule_description"
                        >
                          <span slot="reference" class="tips buleBg">?</span>
                        </el-popover>
                      </div>
                    </td>
                    <td><el-checkbox v-model="item.radioGJ"></el-checkbox></td>
                    <td><el-checkbox v-model="item.radioSK"></el-checkbox></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="footed">
          <el-button
            type="info"
            class="btn"
            size="mini"
            v-show="stepStatus > 0"
            @click="stepStatus--"
          >
            {{ text_btn_up }}
          </el-button>
          <el-button type="primary" class="btn" size="mini" @click="next_step">
            {{ text_btn_down }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/quality'
import indoorMixins from '../indoorMixins'

export default {
  props: ['hospital_id', 'optionsJh_all'],
  mixins: [indoorMixins],
  data() {
    return {
      dialogVisible: false,
      stepStatus: 0,

      data_qcp_info: {
        //质控品信息数据
        instrument_id: '',
        testing_id: '',
        valueNd: 2,
        qc_serum_name: '',
        qc_batch: '',
        serum_source: 2,
        start_date: '',
      },
      optionsXm: [
        { testing_id: 4, testing_unique: 'hs-CRP' },
        { testing_id: 11, testing_unique: 'SAA' },
      ], // 项目
      //来源
      optionsLy: [
        {
          value: 1,
          label: '临检中心',
        },
        {
          value: 2,
          label: '上海奥普',
        },
        {
          value: 3,
          label: '上海昆涞',
        },
        {
          value: 4,
          label: '第三方',
        },
      ],
      list_qcp_num: [], //设置质控号列表数
      list_qcp_target: [], //设置均值列表
      list_setRule: [], //靶值列表
      data_create_qc: [],
      radio: '1',
      radio2: '2',
      data_qcp_num: '',
      checked: '',
    }
  },
  computed: {
    getstepBox() {
      return (status) => {
        return status > this.stepStatus
          ? 'wait'
          : status === this.stepStatus
          ? 'process'
          : 'success'
      }
    },
    text_btn_up() {
      let text = ''
      switch (this.stepStatus) {
        case 1:
          text = '上一步：基本信息'
          break
        case 2:
          text = '上一步：设置质控号'
          break
        case 3:
          text = '上一步：设置均值'
          break
        default:
          break
      }
      return text
    },
    text_btn_down() {
      let text = ''
      switch (this.stepStatus) {
        case 0:
          text = '下一步：设置质控号'
          break
        case 1:
          text = '下一步：设置均值'
          break
        case 2:
          text = '下一步：设置质控规则'
          break
        case 3:
          text = '完\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0成'
          break
        default:
          break
      }
      return text
    },
  },
  methods: {
    /* 打开和关闭弹窗，其他页面也会调用 */
    open_dialog() {
      this.stepStatus = 0
      ;(this.data_qcp_info = {
        instrument_id: '',
        testing_id: '',
        valueNd: 2,
        qc_serum_name: '',
        qc_batch: '',
        serum_source: 2,
        start_date: '',
      }),
        (this.dialogVisible = true)
    },
    close_dialog() {
      this.dialogVisible = false
    },
    /* 点击下一步的操作 */
    next_step() {
      switch (this.stepStatus) {
        case 0:
          // 判断输入合法性
          for (let key in this.data_qcp_info) {
            if (
              this.data_qcp_info[key] == '' ||
              this.data_qcp_info[key] == null ||
              this.data_qcp_info[key].length == 0
            ) {
              this.$message.error('请填写完整相关数据')
              return
            }
          }
          // 判断需要新增多少质控品
          let arr = []
          this.list_qcp_num = []
          this.list_qcp_target = []
          this.data_qcp_info.instrument_id.forEach((temp) => {
            let obj_qcp_num = {
              instrumentText: temp,
              instrumentRowspan: this.data_qcp_info.testing_id.length * 2,
              testingList: [],
            }
            let obj_qcp_target = {
              instrumentText: temp,
              instrumentRowspan: this.data_qcp_info.testing_id.length * 2,
              testingList: [],
            }
            this.data_qcp_info.testing_id.forEach((e) => {
              arr.push({
                hospital_id: this.hospital_id,
                instrument_id: temp,
                testing_id: e,
                qc_serum_name: this.data_qcp_info.qc_serum_name,
                qc_batch: this.data_qcp_info.qc_batch,
                serum_source: this.data_qcp_info.serum_source,
                start_date: this.data_qcp_info.start_date,
              })
              // 设置质控号
              obj_qcp_num.testingList.push({
                testing: e,
                testingText: this.optionsXm.find(
                  (item) => item.testing_id === e,
                ).testing_unique,
                qc_number_data: {
                  qc_number_of_c1: '',
                  qc_number_of_c2: '',
                  qc_sample_number_of_c1: '',
                  qc_sample_number_of_c2: '',
                },
              })
              // 设置靶值
              obj_qcp_target.testingList.push({
                testing: e,
                testingText: this.optionsXm.find(
                  (item) => item.testing_id === e,
                ).testing_unique,
                is_using_fixed: true,
                fixed_info: {
                  fixed_target_c1: '',
                  fixed_target_c1: '',
                  fixed_sd_c1: '',
                  fixed_sd_c2: '',
                },
                setting_target_times: '',
              })
            })
            this.list_qcp_num.push(obj_qcp_num)
            this.list_qcp_target.push(obj_qcp_target)
          })
          this.data_create_qc = arr
          this.stepStatus++
          break
        case 1:
          // 判断输入合法性
          for (let i = 0; i < this.list_qcp_num.length; i++) {
            let item = this.list_qcp_num[i]
            for (let j = 0; j < item.testingList.length; j++) {
              let qc_number_data = item.testingList[j].qc_number_data
              if (
                (qc_number_data.qc_number_of_c1 == '') ^
                  (qc_number_data.qc_number_of_c2 == '') ||
                (qc_number_data.qc_sample_number_of_c1 == '') ^
                  (qc_number_data.qc_sample_number_of_c2 == '')
              ) {
                this.$message.warning('每组质控号请填写完整或不填写')
                return
              }
            }
          }
          this.stepStatus++
          break
        case 2:
          for (let i = 0; i < this.list_qcp_target.length; i++) {
            let item = this.list_qcp_target[i]
            for (let j = 0; j < item.testingList.length; j++) {
              let qcTargetData = item.testingList[j]
              if (qcTargetData.is_using_fixed) {
                if (
                  !qcTargetData.fixed_info.fixed_target_c1 ||
                  !qcTargetData.fixed_info.fixed_target_c2 ||
                  !qcTargetData.fixed_info.fixed_sd_c1 ||
                  !qcTargetData.fixed_info.fixed_sd_c2
                ) {
                  this.$message.warning('请填写设定均值数据')
                  return
                }
                if (
                  Math.sign(qcTargetData.fixed_info.fixed_target_c1) == 1 &&
                  Math.sign(qcTargetData.fixed_info.fixed_target_c2) == 1 &&
                  Math.sign(qcTargetData.fixed_info.fixed_sd_c1) == 1 &&
                  Math.sign(qcTargetData.fixed_info.fixed_sd_c2) == 1
                ) {
                } else {
                  this.$message.warning('均值标准差为正数')
                  return
                }
              } else {
                if (!qcTargetData.setting_target_times) {
                  this.$message.warning('请填写定靶次数')
                  return
                }
                if (
                  Number(qcTargetData.setting_target_times) >= 2 &&
                  Number.isInteger(Number(qcTargetData.setting_target_times))
                ) {
                } else {
                  
                  this.$message.warning('定靶次数为大于1的正整数')
                  return
                }
              }
            }
          }
          this.list_setRule = JSON.parse(
            JSON.stringify({ list_qcp_rule: this.list_qcp_rule }),
          ).list_qcp_rule
          this.list_setRule[0].radioGJ = true
          this.list_setRule[2].radioSK = true
          this.stepStatus++
          break
        case 3:
          this.data_create_qc.forEach((item) => {
            let temp_qcp_num = this.list_qcp_num.find(
              (e) => e.instrumentText === item.instrument_id,
            )
            temp_qcp_num = temp_qcp_num.testingList.find(
              (e) => e.testing === item.testing_id,
            )
            let temp_qcp_target = this.list_qcp_target.find(
              (e) => e.instrumentText === item.instrument_id,
            )
            temp_qcp_target = temp_qcp_target.testingList.find(
              (e) => e.testing === item.testing_id,
            )
            item.is_using_fixed = temp_qcp_target.is_using_fixed
            ;(item.setting_target_times = temp_qcp_target.setting_target_times),
              (item.fixed_info = temp_qcp_target.fixed_info)
            item.qc_number_data = temp_qcp_num.qc_number_data
          })
          this.loadingShow('保存中,请稍后')

          api.create_qc_serum(this.data_create_qc).then((res) => {
            // 设置规则
            let qc_rule_data = {}
            this.list_setRule.forEach((e) => {
              qc_rule_data[e.field] = e.radioSK
                ? true
                : e.radioGJ
                ? false
                : null
            })
            this.data_create_qc.forEach((e) => {
              let rule_param = {
                hospital_id: e.hospital_id,
                is_target: 0,
                qc_serum_id: `${e.instrument_id}-${e.testing_id}-${e.qc_batch}`,
                qc_rule_data,
              }
              api.set_qc_rule(rule_param).then((res) => {})
            })

            this.$emit('addSuccess', {})
          })
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .dialog_right {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  height: 100vh;
  .el-dialog__header {
    height: 0;
    padding: 1px;
  }
  .el-dialog__body {
    margin: $gap;
    padding: $gap;
    height: 95vh;
  }
}

.GJ {
  color: $alarm-color;
}
.SK {
  color: $incontrollable-color;
}
.rule-box {
  display: flex;
  & > p {
    width: 50%;
  }
  .tips {
    cursor: pointer;
    width: px(14);
    height: px(14);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 50%;
    font-size: px(13);
  }
  .buleBg {
    background: #00afe9;
  }
}

.add-box {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $dialog-title-size;
    border-bottom: $border-color solid 1px;
    p {
      font-weight: bold;
      color: #111111;
      font-size: $dialog-title-size;
      padding-bottom: $gap;
      width: 20%;
    }
  }
  .body {
    margin: $gap;
    height: 85vh;
    overflow: auto;
    .content {
      margin-top: px(80);
    }
    .qcp-info-box {
      display: flex;
      justify-content: center;
      ::v-deep .multiple-input {
        .el-tag.el-tag--info {
          background-color: #fff;
        }
        .el-tag.el-tag--info .el-tag__close {
          color: #fafafa;
        }
      }

      & > ul {
        width: 80%;
        li {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 2 * $gap;
          gap: $gap;
          p:first-child {
            flex: 3;
            font-size: $dialog-text-size;
            text-align: right;
          }
          p:last-child {
            flex: 7;
            text-align: left;
            font-size: $btn-text-size;
            ::v-deep .el-input__inner {
              width: px(369);
            }
            ::v-deep .el-input {
              width: px(369);
            }
            //::v-deep .el-select{
            //  width: 100%;
            //}
            //::v-deep .el-input__inner {
            //  width: 100%;
            //}
            //::v-deep .el-date-editor {
            //  width: 100%;
            //}
          }
        }
      }
    }
    .qcp-num-box {
      .tip {
        margin-top: $gap;
        font-size: $btn-text-size;
        & > span {
          color: red;
        }
      }
      .qcp-tbody:nth-child(odd) {
        background-color: #fafafa;
      }
      .qcp-tbody {
        td {
          padding: $gap 0;
        }
      }
    }
    .qcp-target-box {
      .qcp-thead {
        th {
          padding-left: 3/2 * $gap;
          padding-right: 3/2 * $gap;
        }
      }
      .qcp-tbody:nth-child(odd) {
        background-color: #fafafa;
      }

      .qcp-tbody {
        td {
          padding: $gap 0;
        }
        ul {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: $gap;
          li {
            width: 90%;
            display: flex;
            align-items: center;
            gap: $gap;
            p:first-child {
              flex: 3;
              text-align: right;
            }
            p:last-child {
              flex: 7;
              text-align: left;
              ::v-deep .el-input__inner {
                width: 100%;
              }
            }
          }
        }
        .qcp-target-radio-box {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          gap: 2 * $gap;
          .qcp-target-radio {
            width: 100%;
            margin-right: 0;
          }
        }
        .input-td {
          width: 40%;
        }
        .target-num-input {
          ::v-deep .el-input__inner {
            width: 70%;
          }
        }
      }
    }
    .qcp-rule-box {
      display: flex;
      justify-content: center;
      .qcp-rule-table {
        width: 70%;
        td {
          padding: $gap;
        }
      }
    }
  }
  .footed {
    text-align: center;
    margin-top: $gap;
    .btn {
      width: 25%;
    }
  }
}

.step-box {
  margin: $gap 0 3 * $gap 0;
  display: flex;
  align-items: center;
  & > p {
    height: px(8);
    flex: 1;
    background-color: #d7d7d7;
  }
  & > div {
    height: px(34);
    width: px(34);
    border-radius: 50%;
    border: #797979 px(1) solid;
    position: relative;
    & > p {
      height: px(18);
      width: px(18);
      position: absolute;
      border-radius: 50%;
      border: #797979 px(1) solid;
      top: px(7);
      left: px(7);
    }
    & > span {
      font-size: $dialog-title-size;
      display: inline-block;
      width: px(300);
      text-align: center;
      position: absolute;
      bottom: -px(30);
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  .success {
    & > p {
      background-color: #67c23a;
    }
    & > span {
      color: #67c23a;
    }
  }
  .process {
    & > p {
      background-color: #169bd5;
    }
    & > span {
      color: #169bd5;
    }
  }
  .wait {
    & > p {
      background-color: #d7d7d7;
    }
    & > span {
      color: #d7d7d7;
    }
  }
}

.qcp-table {
  width: 100%;
  height: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border-color: $border-color;
  tr {
    width: 100%;
    text-align: center;
  }
  th {
    font-size: $table-th-size;
  }
  td {
    font-size: $table-td-size;
  }
  .qcp-thead {
  }
  .qcp-tbody {
  }
}

.btn {
  font-size: $btn-text-size;
}
</style>